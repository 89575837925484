import React, { useState } from 'react';
import { useGetCategoriesQuery, useEditCategoryMutation, useAddCategoryMutation } from '../store/apiSlice';

export default function JobCategoriesManager() {
  const { data: categories, isLoading, isError } = useGetCategoriesQuery();
  const [editCategory, { isLoading: isEditing }] = useEditCategoryMutation();
  const [addCategory] = useAddCategoryMutation();
  const [openMasterCategory, setOpenMasterCategory] = useState({});
  const [openSubCategory, setOpenSubCategory] = useState({});
  const [editing, setEditing] = useState({});
  const [showAddCategoryForm, setShowAddCategoryForm] = useState(false);
  const [showAddSpecialtyForm, setShowAddSpecialtyForm] = useState(false);

  const [newSpecialty, setNewSpecialty] = useState({
    name: '',
    pageSlug: '',
    subCategoryId: null,
    masterCategoryId: null,
  });

  const handleAddCategory = async (type) => {
    if (!newSpecialty.name) {
      alert('Name is required');
      return;
    }

    if (type === 'specialty' && (!newSpecialty.pageSlug || !newSpecialty.subCategoryId)) {
      alert('Page Slug and Subcategory ID are required for specialties');
      return;
    }

    if (type === 'sub' && !newSpecialty.masterCategoryId) {
      alert('Master Category ID is required for subcategories');
      return;
    }

    try {
      await addCategory({
        ...newSpecialty,
        type,
      }).unwrap();

      alert(`${type === 'sub' ? 'Subcategory' : 'Specialty'} added successfully`);
      type === 'sub' ? setShowAddCategoryForm(false) : setShowAddSpecialtyForm(false);
      setNewSpecialty({ name: '', pageSlug: '', subCategoryId: null, masterCategoryId: null });
    } catch (error) {
      console.error(`Error adding ${type}:`, error);
      alert(`Failed to add ${type}`);
    }
  };


  const handleEdit = async ({ id, name, metaTitle, pageSlug, featured, pageH1, pageContent, metaDescription, type }) => {
    try {
      await editCategory({
        id,
        name,
        metaTitle,
        pageSlug,
        pageH1,
        pageContent,
        metaDescription,
        featured,
        type,
      }).unwrap();
      setEditing((prev) => {
        const updated = { ...prev };
        delete updated[id];
        return updated;
      });
      alert('Category updated successfully');
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  const handleFieldChange = (id, field, value) => {
    setEditing((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  if (isLoading) return <div className="text-center text-gray-600 mt-8">Loading...</div>;
  if (isError) return <div className="text-center text-red-500 mt-8">Error loading categories</div>;

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Job Categories Manager</h1>

      <div className="grid gap-6">
        {Object.keys(categories).map((masterCategory) => {
          const masterData = categories[masterCategory];

          return (
            <div
              key={masterData.id}
              className="border rounded-lg shadow-md bg-white p-4 hover:shadow-lg transition-shadow"
            >
              {/* Master Category Header */}
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() =>
                  setOpenMasterCategory((prev) => ({
                    ...prev,
                    [masterData.id]: !prev[masterData.id],
                  }))
                }
              >
                <h2 className="text-xl font-semibold text-gray-800">{masterCategory}</h2>
                <button className="text-blue-600 hover:underline">
                  {openMasterCategory[masterData.id] ? 'Hide' : 'Show'}
                </button>
              </div>

              {/* Subcategories */}
              {openMasterCategory[masterData.id] && (
                <div className="mt-4 space-y-4 ml-4">
                  <div className="flex justify-center mb-4">
                    <button
                      className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700"
                      onClick={() => {
                        setShowAddCategoryForm(true);
                        setNewSpecialty({ ...newSpecialty, masterCategoryId: masterData.id });
                      }}
                    >
                      Add Subcategory ({masterCategory})
                    </button>
                  </div>
                  {showAddCategoryForm && newSpecialty.masterCategoryId === masterData.id && (
                    <div className="p-4 bg-gray-100 rounded-lg">
                      <label className="block text-sm font-medium text-gray-600">Name</label>
                      <input
                        type="text"
                        className="w-full p-2 border rounded"
                        value={newSpecialty.name}
                        onChange={(e) => setNewSpecialty((prev) => ({ ...prev, name: e.target.value }))}
                      />
                      <label className="block text-sm font-medium text-gray-600 mt-4">
                        Page Slug
                      </label>
                      <input
                        type="text"
                        className="w-full p-2 border rounded"
                        value={newSpecialty.pageSlug}
                        onChange={(e) =>
                          setNewSpecialty((prev) => ({
                            ...prev,
                            pageSlug: e.target.value,
                          }))
                        }
                      />

                      <button
                        className="mt-4 bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
                        onClick={() => handleAddCategory('sub')}
                      >
                        Submit
                      </button>
                      <button
                        className="ml-2 bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
                        onClick={() => setShowAddCategoryForm(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  )}

                  {Object.keys(masterData.subCategories).map((subCategoryKey) => {
                    const subCategoryData = masterData.subCategories[subCategoryKey];

                    return (
                      <div
                        key={subCategoryData.id}
                        className="border rounded-lg p-4 bg-gray-50 hover:bg-gray-100"
                      >
                        <div className="flex justify-between items-center">
                          <div>
                            <span className="block font-medium text-lg text-gray-700">
                              {subCategoryKey}
                            </span>
                            {subCategoryData.metaTitle && (
                              <p className="text-sm text-gray-500">
                                Meta Title: {subCategoryData.metaTitle}
                              </p>
                            )}
                            {subCategoryData.pageSlug && (
                              <p className="text-sm text-gray-500">
                                Page Slug: {subCategoryData.pageSlug}
                              </p>
                            )}
                          </div>
                          <div className="space-x-2">
                            <button
                              className="text-blue-600 hover:underline mr-2"
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditing((prev) => ({
                                  ...prev,
                                  [subCategoryData.id]: prev[subCategoryData.id]
                                    ? undefined
                                    : { ...subCategoryData },
                                }));
                              }}
                            >
                              {editing[subCategoryData.id] ? 'Cancel' : 'Edit'}
                            </button>
                            <span className='text-blue-500'>|</span>
                            <button
                              className="text-blue-600 hover:underline"
                              onClick={() =>
                                setOpenSubCategory((prev) => ({
                                  ...prev,
                                  [subCategoryData.id]: !prev[subCategoryData.id],
                                }))
                              }
                            >
                              {openSubCategory[subCategoryData.id]
                                ? 'Hide Specialties'
                                : 'Show Specialties'}
                            </button>
                          </div>
                        </div>

                        {/* Edit Subcategory */}
                        {editing[subCategoryData.id] && (
                          <div className="mt-4 grid grid-cols-2 gap-4 bg-white p-4 border rounded-lg">
                            <div>
                              <label className="block text-sm font-medium text-gray-600">Name</label>
                              <input
                                className="w-full mt-1 p-2 border rounded"
                                defaultValue={subCategoryKey || ''}
                                onChange={(e) =>
                                  handleFieldChange(subCategoryData.id, 'name', e.target.value)
                                }
                                disabled
                              />
                              <label className="block text-sm font-medium text-gray-600 mt-4">
                                Meta Title
                              </label>
                              <input
                                className="w-full mt-1 p-2 border rounded"
                                defaultValue={subCategoryData.metaTitle || ''}
                                onChange={(e) =>
                                  handleFieldChange(subCategoryData.id, 'metaTitle', e.target.value)
                                }
                              />
                              <label className="block text-sm font-medium text-gray-600 mt-4">
                                Page Slug
                              </label>
                              <input
                                className="w-full mt-1 p-2 border rounded"
                                defaultValue={subCategoryData.pageSlug || ''}
                                onChange={(e) =>
                                  handleFieldChange(subCategoryData.id, 'pageSlug', e.target.value)
                                }
                              />
                              <label className="block text-sm font-medium text-gray-600 mt-4">
                                Page H1
                              </label>
                              <input
                                className="w-full mt-1 p-2 border rounded"
                                defaultValue={subCategoryData.pageH1 || ''}
                                onChange={(e) =>
                                  handleFieldChange(subCategoryData.id, 'pageH1', e.target.value)
                                }
                              />
                            </div>
                            <div>
                              <label className="block text-sm font-medium text-gray-600">Page Content</label>
                              <textarea
                                className="w-full mt-1 p-2 border rounded"
                                defaultValue={subCategoryData.pageContent || ''}
                                rows="4"
                                onChange={(e) =>
                                  handleFieldChange(subCategoryData.id, 'pageContent', e.target.value)
                                }
                              />
                              <label className="block text-sm font-medium text-gray-600 mt-4">
                                Meta Description
                              </label>
                              <textarea
                                className="w-full mt-1 p-2 border rounded"
                                defaultValue={subCategoryData.metaDescription || ''}
                                rows="4"
                                onChange={(e) =>
                                  handleFieldChange(subCategoryData.id, 'metaDescription', e.target.value)
                                }
                              />
                              <button
                                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                                onClick={() =>
                                  handleEdit({ ...editing[subCategoryData.id], type: 'sub' })
                                }
                              >
                                Save Changes
                              </button>
                            </div>
                          </div>
                        )}

                        {/* Specialties */}
                        {openSubCategory[subCategoryData.id] && (
                          <div className="bg-gray-100 rounded-xl p-4 mt-4">
                            <button
                              className="flex bg-blue-600 text-white px-4 mx-auto py-2 rounded hover:bg-blue-700 mb-4"
                              onClick={() => {
                                setShowAddSpecialtyForm(true);
                                setNewSpecialty((prev) => ({
                                  ...prev,
                                  subCategoryId: subCategoryData.id,
                                  masterCategoryId: masterData.id,
                                }));
                              }}
                            >
                              Add Specialty ({subCategoryKey})
                            </button>

                            {showAddSpecialtyForm && newSpecialty.subCategoryId === subCategoryData.id && (
                              <div className="mt-4 p-4 bg-gray-100 rounded-lg">
                                <label className="block text-sm font-medium text-gray-600">Name</label>
                                <input
                                  type="text"
                                  className="w-full p-2 border rounded"
                                  value={newSpecialty.name}
                                  onChange={(e) =>
                                    setNewSpecialty((prev) => ({
                                      ...prev,
                                      name: e.target.value,
                                    }))
                                  }
                                />

                                <label className="block text-sm font-medium text-gray-600 mt-4">
                                  Page Slug
                                </label>
                                <input
                                  type="text"
                                  className="w-full p-2 border rounded"
                                  value={newSpecialty.pageSlug}
                                  onChange={(e) =>
                                    setNewSpecialty((prev) => ({
                                      ...prev,
                                      pageSlug: e.target.value,
                                    }))
                                  }
                                />

                                <button
                                  className="mt-4 bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
                                  onClick={() => handleAddCategory('specialty')}
                                >
                                  Submit
                                </button>
                                <button
                                  className="ml-2 bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
                                  onClick={() => setShowAddSpecialtyForm(false)}
                                >
                                  Cancel
                                </button>
                              </div>
                            )}
                            {subCategoryData.specialties.map((specialty) => (
                              <div
                                key={specialty.id}
                                className="flex justify-between items-center bg-white border border-gray-200 shadow-sm rounded-lg p-4 mb-4 hover:shadow-md transition-shadow"
                              >
                                {editing[specialty.id] ? (
                                  <div className="grid grid-cols-2 gap-6 w-full">
                                    <div className="flex flex-col gap-4">
                                      <label className="block text-sm font-medium text-gray-600">
                                        Specialty Name
                                      </label>
                                      <input
                                        className="w-full p-2 border rounded focus:outline-blue-500"
                                        defaultValue={specialty.name || ''}
                                        onChange={(e) => handleFieldChange(specialty.id, 'name', e.target.value)}
                                        disabled
                                      />
                                      <label className="block text-sm font-medium text-gray-600">
                                        Meta Title
                                      </label>
                                      <input
                                        className="w-full p-2 border rounded focus:outline-blue-500"
                                        defaultValue={specialty.metaTitle || ''}
                                        onChange={(e) => handleFieldChange(specialty.id, 'metaTitle', e.target.value)}
                                      />
                                      <label className="block text-sm font-medium text-gray-600">
                                        Page Slug
                                      </label>
                                      <input
                                        className="w-full p-2 border rounded focus:outline-blue-500"
                                        defaultValue={specialty.pageSlug || ''}
                                        onChange={(e) => handleFieldChange(specialty.id, 'pageSlug', e.target.value)}
                                      />
                                      <label className="block text-sm font-medium text-gray-600">
                                        Page H1
                                      </label>
                                      <input
                                        className="w-full p-2 border rounded focus:outline-blue-500"
                                        defaultValue={specialty.pageH1 || ''}
                                        onChange={(e) => handleFieldChange(specialty.id, 'pageH1', e.target.value)}
                                      />
                                      <div className="flex items-center gap-2">
                                        <input
                                          type="checkbox"
                                          checked={editing[specialty.id]?.featured || specialty.featured}
                                          onChange={(e) => handleFieldChange(specialty.id, 'featured', e.target.checked)}
                                        />
                                        <span className="text-gray-700">Featured</span>
                                      </div>
                                    </div>
                                    <div className="flex flex-col gap-4">
                                      <label className="block text-sm font-medium text-gray-600">
                                        Page Content
                                      </label>
                                      <textarea
                                        className="w-full p-2 border rounded focus:outline-blue-500"
                                        defaultValue={specialty.pageContent || ''}
                                        rows="4"
                                        onChange={(e) => handleFieldChange(specialty.id, 'pageContent', e.target.value)}
                                      />
                                      <label className="block text-sm font-medium text-gray-600">
                                        Meta Description
                                      </label>
                                      <textarea
                                        className="w-full p-2 border rounded focus:outline-blue-500"
                                        defaultValue={specialty.metaDescription || ''}
                                        rows="4"
                                        onChange={(e) => handleFieldChange(specialty.id, 'metaDescription', e.target.value)}
                                      />

                                    </div>
                                    <button
                                      className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                                      onClick={() => handleEdit({ ...editing[specialty.id], type: 'specialty' })}
                                    >
                                      Save Changes
                                    </button>
                                  </div>
                                ) : (
                                  <div className="w-full flex justify-between items-start">
                                    <div className="flex flex-col gap-2">
                                      <span className="text-lg font-semibold text-gray-800">{specialty.name}</span>
                                      {specialty.metaTitle && <div className="text-sm text-gray-600">Meta Title: {specialty.metaTitle}</div>}
                                      {specialty.pageSlug && <div className="text-sm text-gray-600">Page URL: {specialty.pageSlug}</div>}
                                      {specialty.pageH1 && <div className="text-sm text-gray-600">Page H1: {specialty.pageH1}</div>}
                                      {specialty.pageContent && (
                                        <div className="text-sm text-gray-600">Page Content: {specialty.pageContent}</div>
                                      )}
                                      {specialty.metaDescription && (
                                        <div className="text-sm text-gray-600">
                                          Meta Description: {specialty.metaDescription}
                                        </div>
                                      )}
                                      <div className="text-sm text-gray-600">Featured: {specialty.featured ? 'Yes' : 'No'}</div>
                                    </div>

                                  </div>
                                )}
                                <button
                                  className="text-blue-600 hover:underline"
                                  onClick={() =>
                                    setEditing((prev) => ({
                                      ...prev,
                                      [specialty.id]: prev[specialty.id] ? undefined : { ...specialty },
                                    }))
                                  }
                                >
                                  {editing[specialty.id] ? 'Cancel' : 'Edit'}
                                </button>
                              </div>
                            ))}
                          </div>
                        )}

                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
