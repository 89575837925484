import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setEmployer } from '../store/postsSlice';

const UnlimitedClients = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!data || Object.keys(data).length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div className="bg-amber-500 p-6 rounded-lg shadow-black shadow-sm">
      {Object.entries(data).map(([country, employersArray], i) => (
        (country === "Australia") ? ( // Only render if the country is Australia
          <div key={i} className="mb-8">
            <h3 className="text-gray-600 font-bold text-lg mb-2 underline">{country}</h3>
            <div className="grid grid-cols-5 gap-4">
              <div className="col-span-2 font-bold">Company</div>
              <div className="col-span-1 font-bold">No. listed on AJ (sales leads)</div>
              <div className="col-span-1 font-bold">No. of jobs on company website</div>
              <div className="col-span-1 font-bold">Jobs to be loaded</div>
            </div>
            <div className="overflow-y-auto max-h-60 custom-scrollbar bg-white border rounded-xl mb-2 p-2">
              {employersArray.map(({ id, company_name, job_count, approxJobQtyWebsite, logo, salesLeads_count }, j) => (
                (company_name === "Bond University" || company_name === "Queensland University of Technology (QUT)" || company_name === "University of Canberra (UC)") ? (
              < div key = { j } className = "grid grid-cols-5 gap-4 mb-2" >
                    <div className="col-span-2">
                      <span
                        className="text-gray-600 hover:underline hover:text-blue-500 cursor-pointer"
                        onClick={() => {
                          dispatch(setEmployer({ employer_id: id, company_name, logo }));
                          navigate(`/employers-center/${id}`);
                        }}
                      >
                        {company_name}
                      </span>
                    </div>
                    <div className="col-span-1">
                      {job_count} (<span style={{ color: 'red' }}>{salesLeads_count}</span>)
                    </div>  
                    <div className="col-span-1">{approxJobQtyWebsite > 0 ? approxJobQtyWebsite : "N/A"}</div>
                    <div className="col-span-1">{approxJobQtyWebsite > job_count ? approxJobQtyWebsite - job_count : "N/A"}</div>
                  </div>
            ) : null
              ))}
          </div>
          </div>
  ) : null
      ))}
    </div >
  );
};

export default UnlimitedClients;
