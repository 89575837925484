import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // React Router v6 hooks
import axios from 'axios';
import { useRegisterUserMutation } from './auth/authApiSlice';

const Activate = () => {
    const [registerUser, { isLoading: isRegistering }] = useRegisterUserMutation();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        password: '',
    });
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const getQueryParams = (query) => new URLSearchParams(query);

    // Redirect to home if required parameters are missing
    useEffect(() => {
        const query = getQueryParams(location.search);
        const token = query.get('token');
        const email = query.get('email');

        if (!token || !email) {
            setMessage('Invalid activation link. Redirecting to home...');
            setTimeout(() => navigate('/'), 3000);
        }
    }, [location, navigate]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const query = getQueryParams(location.search);
        const token = query.get('token');
        const email = query.get('email');

        try {
            const response = await registerUser({
                token,
                email,
                ...formData,
            }).unwrap();

            console.log(response)
            console.log(response.data)

            if (response.user) {
                setMessage('Account activated successfully! Redirecting to login...');
                setTimeout(() => navigate('/login'), 5000);
            } else {
                setMessage('Unexpected response. Please try again.');
            }
        } catch (error) {
            setMessage(
                error?.data?.message || 'Failed to activate account. Please try again.'
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-slate-50">
            <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
                <img
                    src="/brands/academic-jobs-logo.svg"
                    alt="AcademicJobs Logo"
                    className="w-[200px] mx-auto mb-4"
                />
                <h1 className="text-2xl font-semibold text-center text-gray-700 mb-4">
                    Activate Your Account
                </h1>
                <p className="text-center text-gray-500 mb-6">
                    Please provide the required details to complete your registration.
                </p>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label
                            htmlFor="firstName"
                            className="block text-sm font-medium text-gray-600"
                        >
                            First Name
                        </label>
                        <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            required
                            className="mt-1 w-full px-4 py-2 border rounded-md focus:ring focus:ring-amber-300 focus:outline-none"
                        />
                    </div>

                    <div>
                        <label
                            htmlFor="lastName"
                            className="block text-sm font-medium text-gray-600"
                        >
                            Last Name
                        </label>
                        <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            required
                            className="mt-1 w-full px-4 py-2 border rounded-md focus:ring focus:ring-amber-300 focus:outline-none"
                        />
                    </div>

                    <div>
                        <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-600"
                        >
                            Password
                        </label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            required
                            className="mt-1 w-full px-4 py-2 border rounded-md focus:ring focus:ring-amber-300 focus:outline-none"
                        />
                    </div>

                    <button
                        type="submit"
                        disabled={loading}
                        className={`w-full px-4 py-2 text-white rounded-md transition ${
                            loading
                                ? 'bg-gray-400 cursor-not-allowed'
                                : 'bg-amber-500 hover:bg-amber-600'
                        }`}
                    >
                        {loading ? 'Activating...' : 'Complete Registration'}
                    </button>
                </form>

                {message && (
                    <div className="mt-4 text-center">
                        <p className="text-md font-bold text-gray-700">{message}</p>
                    </div>
                )}

                {!loading && message.includes('failed') && (
                    <button
                        onClick={() => navigate('/')}
                        className="mt-4 w-full px-4 py-2 text-amber-600 border border-amber-600 rounded-md hover:bg-amber-50"
                    >
                        Back to Home
                    </button>
                )}
            </div>
        </div>
    );
};

export default Activate;
