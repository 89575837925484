import React from "react";
import { useNavigate } from "react-router-dom";
import ContactCard from "../components/ContactCard";
import { useSelector, useDispatch } from "react-redux";
import { setJobCredits } from "../store/postsSlice";
import { useGetUserByIdQuery } from "../store/apiSlice";
import { useGetEmployerQuery } from "../store/apiSlice";

function AdminHome_Customer(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.auth.userInfo);
  const { data } = useGetEmployerQuery(userInfo.employer_id);

  const { data: userData } = useGetUserByIdQuery({ id: userInfo.id });
  let content, content1;
  // if (userData) {
  //   console.log(userData)
  //   dispatch(setJobCredits(userData?.task.jobCredits));
  // }
  return (
    <>
      {data && (
        <>
          {/* <div className="grid grid-cols-1 gap-4 md:grid-cols-5"> */}
          <div className="col-span-1 md:col-span-4">
            <h2 className="text-gray-blue font-bold text-4xl mb-4">
              University Profile
            </h2>
            <div
              className="w-full"
              dangerouslySetInnerHTML={{ __html: data.company_description }}
            />
            {/* </div>
            <div className="col-span-1 md:col-span-1"></div> */}
          </div>
          <ContactCard personId="jarrod" forceClass="mt-10" />
        </>
      )}
    </>
  );
}

export default AdminHome_Customer;
