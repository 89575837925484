const ACLogo = () => {
  return (
    <>
      <span className="text-aj">Academic</span>
      <span className="text-black">Connect</span>
    </>
  );
};

export default ACLogo;
