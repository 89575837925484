// invoiceLoadingHtml.js
export const invoiceLoadingHtml = `
  <html>
    <head>
      <title>Loading Invoice...</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          background-color: #f4f4f4;
          margin: 0;
        }
        .loading {
          text-align: center;
        }
        .spinner {
          margin: 20px auto;
          width: 40px;
          height: 40px;
          border: 5px solid #f3f3f3;
          border-top: 5px solid #3498db;
          border-radius: 50%;
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      </style>
    </head>
    <body>
      <div class="loading">
        <h1>Generating Payment Link...</h1>
        <div class="spinner"></div>
      </div>
    </body>
  </html>
`;
