import React, { useState, useEffect } from 'react';
import { useGetEmployersQuery } from '../store/apiSlice';
import EmployerList from './EmployerList';

const SearchResultsEmployers = ({ q }) => {
  const [page, setPage] = useState(0);
  const [noLogo, setNoLogo] = useState(false); // State for noLogo filter

  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetEmployersQuery({ ...q, page, noLogo });

  const toggleNoLogo = () => setNoLogo(!noLogo); // Toggle noLogo filter

  useEffect(() => {
    // Reset page to 0 when noLogo changes
    setPage(0);
  }, [noLogo]);

  const nextPage = () => setPage((prev) => prev + 1);

  let content;
  if (isLoading) {
    content = <div>Loading...</div>;
  } else if (isError) {
    content = <div>Error: {error.message}</div>;
  } else if (isSuccess) {
    content = data?.length > 0 ? (
      <EmployerList data={data} nextPage={nextPage} />
    ) : (
      <div>No employers found.</div>
    );
  }

  return (
    <div>
      <div className="flex items-center mb-4">
        <label htmlFor="noLogo" className="mr-2">
          Show employers with no logo
        </label>
        <input
          id="noLogo"
          type="checkbox"
          checked={noLogo}
          onChange={toggleNoLogo}
        />
      </div>
      {content}
    </div>
  );
};

export default SearchResultsEmployers;
