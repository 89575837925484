import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, Link, NavLink } from "react-router-dom";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { setJobCredits } from "../store/postsSlice";
import UniSearchBlock from "./UniSearchBlock";
import { useForm } from "react-hook-form";
import SearchResultsEmployers from "./SearchResultsEmployers";
import { setJob, setId, setEmployer } from '../store/postsSlice'
import { useGetUserByIdQuery } from "../store/apiSlice"
import { useGetJobPaddingEmployersQuery, useGetSalesNotesByUserQuery } from "../store/apiSlice"
import TopUniversityPadding from "../components/TopUniversityPadding";
import LowJobCountEmlpoyers from "../components/LowJobCountEmlpoyers";
import SearchResults1 from "./SearchResults1";
import { useGetMax5JobsEmployersQuery } from "../store/apiSlice"
import AusNzEmployers from "../components/AUNZEmployers";
import UnlimitedClients from "../components/UnlimitedClients";
import { useGetAUNZEmployersQuery } from "../store/apiSlice"
import ContactsTable from "../components/ContactsTable";
import KeywordSearches from "../components/KeywordSearches";
import { useGetAcademicLeadJobsQuery } from "../store/apiSlice";
import AcademicLeadJobs from "../components/AcademicJobLeads";
import { useGetJobElephantClientsQuery } from "../store/apiSlice";

function AdminHome(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employerID, setEmployerID] = useState(3173)
  const region = useSelector((state) => state.posts.region);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const { data, isLoading, isSuccess } = useGetJobPaddingEmployersQuery();
  const { data: userData } = useGetUserByIdQuery({ id: userInfo.id });
  const { data: AuNzEmployers } = useGetAUNZEmployersQuery({ id: 77 });
  const { data: academicLeadsJobs } = useGetAcademicLeadJobsQuery();
  const { data: jobElephantClientList, isSuccess: successLoadingClients, isLoading: isLoadingClients, error: errorClients } = useGetJobElephantClientsQuery();
  let content, content1, AUNZEmployers1, unlimitedClients;

  const {
    data: dataSalesNotes,
    isLoading: isLoadingSalesNotes,
    isSuccess: isSuccessSalesNotes,
  } = useGetSalesNotesByUserQuery({ id: userInfo.id })
  if (dataSalesNotes?.length > 0) {
    content1 = dataSalesNotes?.map(({ date, note, actionBy, actionDate, writtenBy }, key) => (
      <div
        className=' border rounded border-black px-1 py-1'
        key={key}
      >
        <div className="w-full flex justify-between  text-left text-xs font-bold">
          <div>Date: &nbsp;
            {new Date(date).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}</div>
          <div>
            Follow-up By: &nbsp; {actionBy}
          </div>
          <div>
            Follow-up Date: &nbsp; {actionDate && new Date(actionDate).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </div>
          <div>Written By:  &nbsp; {writtenBy}</div>
        </div>
        <div className="w-full text-left">{note}</div>
      </div>
    ))
  }
  if (isLoading) {
    content = <p>Loading...</p>;
  } else if (isSuccess) {
    content = (
      <div className="bg-slate-200 p-6 rounded-xl mb-8 text-gray-600 font-bold">
        <h1>Job Padding Required</h1>
        <div className="container mx-auto py-8">
          <div className="flex justify-between text-gray-600 mb-2">
            <span className="w-1/3">Region</span>
            <span className="w-1/3">Current Amount</span>
            <span className="w-1/3">Number of jobs to be Loaded</span>
          </div>
          <TopUniversityPadding data={data} />
        </div>
      </div>
    )
  }

  if (userInfo.id === 4) {
    content = <div className="w-full px-6 gap-8 ">
      {content}
    </div>
    AUNZEmployers1 =
      <div className="w-[66%] bg-slate-200 p-6 rounded-xl mb-8 mr-6 ml-4 shadow-black shadow-sm">
        <h1 className="text-gray-600 font-bold p-3">AUST/NZ Job Padding (with sales leads)</h1>
        {<AusNzEmployers data={AuNzEmployers} />}
      </div>
    unlimitedClients =
      <div className="bg-slate-200 p-6 rounded-xl mb-8 mr-6 ml-4 shadow-black shadow-sm">
        <h1 className="text-gray-600 font-bold p-3">Unlimited Clients</h1>
        {<UnlimitedClients data={AuNzEmployers} />}</div>
  }

  return (
    <div className="">
      <h1 className="text-3xl font-bold text-black shadow-xl px-2 pb-4 mt-4 mb-6 ml-6">
        Welcome {userInfo.firstName} !</h1>
      <div className="flex">
        <div className="w-1/2 gap-8">
          {content}
          {unlimitedClients}

          {/* Rendering jobElephantClients */}
          {successLoadingClients && jobElephantClientList.length > 0 ? (
          <div className="bg-slate-200 p-6 rounded-xl mb-8 mr-6 ml-4 shadow-black shadow-sm">
              <h1 className="text-gray-600 font-bold p-3">Job elephant Clients</h1>
              {jobElephantClientList.map((client) => (
                <div key={client.id} className="mb-4 p-1 bg-white border rounded shadow">
                    <span
                      className="text-gray-600 hover:underline hover:text-blue-500 cursor-pointer"
                      onClick={() => {
                        dispatch(setEmployer({ employer_id: client.id, company_name: client.company_name, logo: client.logo }));
                        navigate(`/employers-center/${client.id}`);
                      }}
                    >
                      {client.company_name}
                    </span>                </div>
              ))}
            </div>
          ) : (
            <p>No job elephant clients found.</p>
          )}
        </div>
        {AUNZEmployers1}
      </div>

      <div className="flex">
        <div className="w-3/4 bg-slate-200 p-6 rounded-xl mb-8 mr-6 ml-4">
          <div className="mb-8">
            <h1 className="text-gray-600 font-bold p-3">Sales Notes/ Actions</h1>
            {content1}
          </div>
          <h1 className="text-gray-600 font-bold p-3">Sales Contacts</h1>

          <ContactsTable />

        </div>
        <div className=" bg-slate-200 p-6 rounded-xl mb-8 mr-6 ml-8 shadow-black shadow-sm">
          <h1 className="text-gray-600 font-bold p-3">Google search Links (Keyword Searches)</h1>

          <p className="font-bold text-red-500 mb-4">IMPORTANT TASK FOR ALL:  go to google, search for 'Research Assistant Jobs Melbourne' which will be on the second page, stay on those pages for a while and keep it open while you do other tasks. When possible, please do the same for other states: Canberra, Sydney, Brisbane, Perth & Adelaide! replacing the city name with those cities in your search</p>

          <ul className="list-decimal font-bold ml-8 mb-8">
            <li>With your VPN, click the links below</li>
            <li>Then you scroll down to our Academic Jobs website, click on our link and make sure you stay on our site for 2-5 minutes. NO LESS.</li>
            <li>IMPORTANT: do NOT click the "sponsored" link to Academic Jobs- this will cost us money and should be avoided</li>
          </ul>
          <KeywordSearches userId={userInfo.id} />
        </div>

      </div>
      <div className="flex">
        <div className=" bg-slate-200 p-6 rounded-xl mb-8 mr-6 ml-8 shadow-black shadow-sm">
          <h1 className="text-gray-600 font-bold p-3">Academic Leads Required</h1>
          <AcademicLeadJobs jobs={academicLeadsJobs} />
        </div>
        <div className="w-1/2 bg-slate-200 p-6 rounded-xl mb-8">
          <div className=" w-full bg-slate-200 p-2 rounded-xl ">
            <h1 className=" font-bold mb-2 text-gray-600">Team Notes</h1>
            <div className="rounded-xl w-full custom-scrollbar max-h-[200px] overflow-y-auto border whitespace-pre-wrap bg-white p-3">
              {userData &&
                <div dangerouslySetInnerHTML={{ __html: userData.task.task }} />}
            </div>
          </div>

          <h1 className=" text-gray-600 font-bold">Manila Team</h1>
          <div className="flex gap-4 mb-6">
            <div className="grid grid-cols-4 gap-5 mt-4">
              <figure className="    rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="https://academicjobs.s3.amazonaws.com/img/_misc/420242991_741144318123644_4167791228012772159_n.jpg"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  The Don
                </h2>
              </figure>
              <figure className="    rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="https://academicjobs.s3.amazonaws.com/img/users/laira_andrea_austria.jpg"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1 ">
                  {' '}
                  laira andrea austria
                </h2>
              </figure>
              <figure className="   rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="https://academicjobs.s3.amazonaws.com/img/users/joan_santos.jpg"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl h-4/5 w-40 shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  joan santos
                </h2>
              </figure>
              <figure className="   rounded-3xl">
                <img
                  width={150}
                  height={150}
                  src="https://academicjobs.s3.amazonaws.com/img/users/Bianca_Cacho_ID Picture copy.jpg"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Bianca Cacho
                </h2>
              </figure>
              <figure className="   rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="https://academicjobs.s3.amazonaws.com/img/users/cai_portrait.jpg"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Maria Luisa Violeta
                </h2>
              </figure>
              <figure className="    rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="https://academicjobs.s3.amazonaws.com/img/users/pauline_aura.jpg"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Pauline
                </h2>
              </figure>
              <figure className="   rounded-3xl">
                <img
                  width={150}
                  height={150}
                  src="/John-michael-cruz.png"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  jhon michael cruz
                </h2>
              </figure>
              <figure className="    rounded-3xl ">
                <img
                  width={150}
                  height={130}
                  src="https://academicjobs.s3.amazonaws.com/img/users/mary_rose_joana_pecson.jpg"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  mary rose joana pecson
                </h2>
              </figure>
              <figure className="   rounded-3xl">
                <img
                  width={150}
                  height={150}
                  src="https://academicjobs.s3.amazonaws.com/img/users/ClarissaPacetes.png"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Clarissa Pacetes
                </h2>
              </figure>
            </div>
          </div>
          <h1 className=" text-gray-600 font-bold">Melbourne Team</h1>
          <div className="flex gap-6">
            <div className="grid grid-cols-4 gap-5 mt-4">
              <figure className="rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="/Jarrod.png"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Jarrod
                </h2>
              </figure>
              <figure className="rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="/I-letter.png"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Isabella
                </h2>
              </figure>
              <figure className="rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="/L-letter.png"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Leanne
                </h2>
              </figure>
              <figure className="rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="/W-letter.png"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Walter
                </h2>
              </figure>
              <figure className="rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="/C-letter.png"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Cian
                </h2>
              </figure>

            </div>

          </div>


        </div>
      </div>
    </div>
  );
}
export default AdminHome;
