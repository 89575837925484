import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import JobKeywordSearchBlock from './JobKeywordSearchBlock';
export default function AHEIAComponent({ country }) {
    const countryMap = {
        'United Kingdom': 'United Kingdom',
        UK: 'United Kingdom',
        Australia: 'Australia',
        Canada: 'Canada',
        USA: 'United States',
        'United States': 'United States',
        'New Zealand': 'New Zealand',
        Europe: 'Europe'
    };
    const keyWordRef = useRef(null);
    const cfRef = useRef(null);
    const linkRef = useRef(null);
    const onEditorStateSelect = (category, filter, category01, category01A, category02, category02A, link) => {
        if (filter == 'All Indigenous Jobs in Australia') {
            keyWordRef.current = 'Indigenous'
            return
        }
        //alert(link)
        if (link) {
            linkRef.current = link;
            return
        }
        linkRef.current = null;
        cfRef.current = { category, filter };
        console.log(category, filter);
    };
    const onInputChange = (inputText) => {
        keyWordRef.current = inputText;
    };
    //alert(country)
    //alert(countryMap[country])
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        
        if (linkRef.current) {
            const url = linkRef.current;
            //alert(url); // Just for debugging purposes
            window.open(url, '_blank');
            return
        }
        let q3 = ""
        if (cfRef.current) {
            q3 = `&filter0=[{"category":"${cfRef.current.category}","filter":"${cfRef.current.filter}"}]`
        } else {
            if (keyWordRef?.current?.trim()) {
                q3 = `&q=${keyWordRef?.current?.trim()}`
            }
        }
        const url = `https://www.academicjobs.com/university-jobs?r=Australia${q3}`;
        //alert(url); // Just for debugging purposes
        window.open(url, '_blank');
    };
    return (
        <div className="relative z-50  flex flex-col gap-2">
            <form className="flex flex-col gap-1 items-center md:items-end w-full" onSubmit={handleFormSubmit}>
                <div className="bg-white relative flex flex-col md:flex-row md:gap-2 md:gap-0 mx-18 w-full mt-5 md:border rounded-t-lg md:rounded-lg p-2 md:shadow-md">
                    <div className="relative z-50 flex-grow flex items-center"> {/* Ensure the container is flex and items-center */}
                        <JobKeywordSearchBlock
                            forceClass="mb-6"
                            onSelect={onEditorStateSelect}
                            onInputChange={onInputChange}
                            className="w-full"
                        />
                    </div>
                    <button
                        className="h-[50px] ml-auto px-4 py-2 bg-[#f4a10c] w-full md:w-auto text-white rounded-md rounded-b-lg hover:bg-orange-600 animate-pulse font-bold shadow-md"
                        type="submit"
                    >
                        Search
                    </button>
                </div>
            </form>
            {/* <button
                className=" self-end underline text-gray-400 text-base md:pr-6 font-bold hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-l from-green-400 via-green-400 to-sky-300"
                onClick={async (e) => {
                    e.preventDefault();
                    //alert()
                    window.open = `https://www.academicjobs.com/jobs-advanced-search`;
                }}
            >
                Or Search Globally
            </button> */}
        </div>
    );
}
