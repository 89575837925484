import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGetJobKeywordSuggestionsQuery } from "../store/apiSlice";
const UniSearchBlock = ({
  field,
  country,
  label,
  customKey,
  value1,
  onSelect,
  onInputChange,
  forceClass,
}) => {
  const countryRef = useRef("");
  const [query, setQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(value1 || "");
  const { data: suggestions = [] } = useGetJobKeywordSuggestionsQuery({ query });
  const handleInputClick = () => {
    setShowSuggestions(true);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleInputChange = (e) => {
    //inputRef.current.value = e.target.value;
    setShowSuggestions(true);
    setQuery(e.target.value);
    //leonInputChange(e.target.value);
  };
  useEffect(() => {
    //inputRef.current.value = value1 || '';
    setQuery(value1 || "");
  }, [value1]);
  return (
    <div className="w-full bg-white relative">
      <input
        ref={inputRef}
        type="text"
        value={query}
        onChange={handleInputChange}
        onClick={handleInputClick}
        //placeholder="Type at least 3 characters..."
        // className="w-full font-normal py-1 px-1 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        className={`w-full input input-sm input-bordered  focus:outline-none focus:border-orange-500 `}
        autoComplete="one-time-code"
        name={customKey}
        placeholder={label}
      //onChange={handleInputChange}
      />
      {showSuggestions && (
        <ul className="absolute w-full mt-2 list-none z-50 ">
          {suggestions.map(
            (
              {
                specialty_name, subcategory_column, master_category_name, subcategory_name
              },
              index
            ) => (
              <li
                key={index}
                className="bg-white  w-full py-1 px-1 border-b border-gray-300 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  setQuery(specialty_name ? specialty_name : subcategory_name);

                  onSelect(specialty_name, subcategory_name, subcategory_column, master_category_name)
                }}
              >
                <div className="w-[100%]"><span className="text-sm font-bold">{specialty_name ? specialty_name : subcategory_name}</span><span className="text-xs"> ({master_category_name} {specialty_name ? `- ${subcategory_name}` : ''})</span></div>
              </li>
            )
          )}
        </ul>
      )}
    </div>
  );
};
export default UniSearchBlock;
