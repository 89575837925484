import { Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link, NavLink } from "react-router-dom";
import AdminHome from './AdminHome';
import AdminHome_Customer from './AdminHome_Customer';
import AdminHome_Employer from './AdminHome_Employer';
import AdminHomeSales from './AdminHomeSales';
import AdminHomePartner from './AdminHomePartner';
import Dashboard from './DashBoard';


const AdminHome1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const region = useSelector((state) => state.posts.region);
  const userInfo = useSelector((state) => state.auth.userInfo);
  let content
  if (userInfo.employer_id > 0) {
    content= <Dashboard />
    // content = <AdminHome_Customer/>
  } else if (userInfo.userType==="Manager"){
    content = <AdminHome />
  } else if (userInfo.userType==="Sales"){
    content = <AdminHomeSales />
  } else if (userInfo.userType==="Partner"){
    content = <AdminHomePartner />
  } else {
    content = <AdminHome_Employer />
  }
  return content
}
export default AdminHome1
