import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
const AcademicjobsComponent = () => {
    const styles = {
        iframeContainer: {
            position: "relative",
            height: "100%",
            minHeight: "100vh",
        },
        iframe: {
            height: "100%",
            width: "100%",
            position: "absolute",
            left: 0,
            top: 0,
        }
    }
    return (
        <div style={styles.iframeContainer}>
            {/* <iframe src="https://iloveacademicjobs.com/AHEIAComponent" width={1200} */}
            {/* <iframe src="http://localhost:2997/AHEIAComponent1" style={styles.iframe} frameBorder="0" */}
            <iframe src="https://iloveacademicjobs.com/AHEIAComponent1" style={styles.iframe} height="100%" width="100%" frameBorder="0"
            ></iframe>
        </div>
    );
};
export default AcademicjobsComponent;