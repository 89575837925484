import React from "react";

// Add new contacts to this object as required
const contactCardData = {
  jarrod: {
    imageSrc:
      "https://www.academicjobs.com/_next/image?url=%2Fabout-us%2Fteam%2Fjarrod-kanizay.jpg&w=256&q=75",
    name: "Jarrod Kanizay",
    title:
      "Your personal job advertising assistant!<br />Contact me with any queries you may have.",
    email: "jarrod@academicjobs.com",
    linkedIn:
      "https://www.linkedin.com/in/jarrodkanizay/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    phone: "+61 (0)430 017 783",
    quote: `Use AcademicConnect on all your jobs… it simply works. Get your department teams involved to spread the word today!`,
  },
  grace: {
    imageSrc:
      "https://www.academicjobs.com/_next/image?url=%2Fabout-us%2Fteam%2Fgrace.jpg&w=256&q=75",
    name: "Grace Johnson",
    title: "HR Specialist at AcademicJobs<br />Contact me for any queries.",
    email: "grace@academicjobs.com",
    linkedIn: "https://www.linkedin.com/in/gracejohnson/",
    phone: "+61 (0)430 012 345",
    quote: "Helping you find the right talent for your team!",
  },
};

function ContactCard({
  personId,
  cardHeading = "Your Personal Job Advertising Assistant",
  forceClass = "",
}) {
  const contactInfo = contactCardData[personId.toLowerCase()]; // Fetch contact data dynamically

  if (!contactInfo) {
    return <p>Contact information not found.</p>;
  }

  const { imageSrc, name, title, email, linkedIn, phone, quote } = contactInfo;
  const titleArray = title.split("<br />");

  const FinalTitle = () => (
    <>
      {titleArray.map((title, index) => (
        <span key={index}>
          {title}
          {index !== titleArray.length - 1 && <br />}{" "}
        </span>
      ))}
    </>
  );

  return (
    <div className={`${forceClass}`}>
      {cardHeading && (
        <h3 className="text-gray-blue font-bold text-2xl mb-2">
          {cardHeading}
        </h3>
      )}
      <div className="w-full bg-gray-100 py-4 px-8 flex flex-col items-start gap-4 md:flex-row md:items-center md:justify-between shadow-lg">
        {/* Profile Picture and Name */}
        <div className="flex flex-col md:flex-row items-center gap-4 max-w-[420px]">
          <img
            src={imageSrc}
            alt={`${name}'s profile`}
            className="w-32 h-32 rounded-full object-cover border-2 border-gray-300"
          />
          <div>
            <h2 className="text-xl font-semibold">{name}</h2>
            <p className="text-sm text-gray-600">{<FinalTitle />}</p>
          </div>
        </div>

        {quote && (
          <div className="max-w-[260px] hidden lg:block">
            <h5 className="font-bold text-gray-blue">My top tip:</h5>
            <blockquote className="text-sm italic">“{quote}”</blockquote>
          </div>
        )}

        <img
          src="https://www.academicjobs.com/academic-connect-logo.svg"
          alt="AcademicConnect Logo"
          className="hidden lg:inline-block max-h-[128px]"
        />

        {/* Contact Details */}
        <div className="flex flex-col justify-between md:text-right">
          {/* Email */}
          {email && (
            <p className="text-lg">
              <a
                href={`mailto:${email}`}
                className="text-blue-600 hover:underline"
              >
                {email}
              </a>
            </p>
          )}

          {/* LinkedIn */}
          {linkedIn && (
            <a
              href={linkedIn}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block m-0 ml-auto hover:opacity-70"
            >
              <img
                src="/icons/linkedin-icon.svg"
                alt="LinkedIn Profile"
                className="w-10 h-10 mb-2"
              />
            </a>
          )}

          {/* Phone */}
          {phone && (
            <h3 className="text-gray-blue font-bold m-0">
              <a
                href={`tel:${phone}`}
                className="text-gray-blue font-bold hover:underline"
              >
                {phone}
              </a>
            </h3>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContactCard;
