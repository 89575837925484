import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useUpdateNoteMutation } from "../store/apiSlice";

const NoteAddEdit = ({ note }) => {
  const [updateNote, isUpdateSuccess] = useUpdateNoteMutation();
  const { control, register, reset, handleSubmit, setValue, watch, getValues, formState: { errors }, setError } = useForm();
  const [status, setStatus] = useState(note?.status || 'TODO');
  const [isExpanded, setIsExpanded] = useState(false);
  const [localStatus, setLocalStatus] = useState(status);
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);

  const employeeMap = {
    4: "Jarrod Kanizay",
    161: "Grace"
  };

  const formattedDate = useMemo(() => {
    if (!note?.actionDate) return '';
    const date = new Date(note.actionDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }, [note?.actionDate]);

  useEffect(() => {
    const defaultValues = {
      ...note,
      actionDate: formattedDate,
      actionBy: note?.actionBy ? employeeMap[note.actionBy] : ''
    };
    reset(defaultValues);
  }, [note, formattedDate, reset]);

  useEffect(() => {
    if (isUpdateSuccess) {
      setSavedSuccessfully(true);
      setTimeout(() => setSavedSuccessfully(false), 2000);
      setStatus(localStatus);
    }
  }, [isUpdateSuccess]);

  const onSubmit = async (data) => {
    const actionByNumber = Object.keys(employeeMap).find(key => employeeMap[key] === data.actionBy);
    const updatedData = { ...data, actionBy: actionByNumber, status: localStatus };
    await updateNote({ id: note?.id, ...updatedData });
    reset({ ...note, ...updatedData });
  };

  const handleCheckboxChange = (event) => {
    setLocalStatus(event.target.checked ? 'COMPLETED' : 'TODO');
  };

  const employeeNames = Object.keys(employeeMap).map(key => employeeMap[key]);

  const showActionFields = note?.actionBy > 0 && (status === 'TODO' || status === 'COMPLETED');

  const borderColor = showActionFields
    ? status === 'TODO'
      ? 'border-red-400'
      : 'border-green-400'
    : 'border-gray-400';

  return (
    <div className="overflow-y w-full p-3">
      <div className={`border-2 rounded-xl p-2 ${borderColor}`}>
        <div className="w-full flex justify-between items-center p-4 text-left text-xs font-bold">
          <div>
            <div className="flex flex-col mb-2 md:flex-row">
              <div className="mb-2 md:mr-6 md:mb-0 flex flex-col">
                <label className="label-text text-xs">Date:</label>
                <span>
                  {new Date(note.date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </span>
              </div>
              <div className="flex flex-col mb-2 md:mr-6 md:mb-0">
                <label className="label-text text-xs">Written By:</label>
                <span>{note.writtenBy || 'N/A'}</span>
              </div>
              {showActionFields && note.actionType && (
                <div className="flex flex-col">
                  <label className="label-text text-xs">Action Type:</label>
                  <span>{note.actionType}</span>
                </div>
              )}
            </div>
          </div>
          <button
            type="button"
            className="focus:outline-none"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? '▲' : '▼'}
          </button>
        </div>
        {isExpanded && (
          <form className="relative gap-1" onSubmit={handleSubmit(onSubmit)}>
            <textarea
              id="description-input"
              name="note"
              className="w-full h-[200px] px-4 py-3 border border-black rounded-lg text-black focus:outline-none focus:border-orange-500"
              placeholder="Please write sales notes here"
              {...register("note")}
            />
            {showActionFields && (
              <div className="flex flex-col md:flex-row gap-4 pt-8">
                <div className="mb-1 md:mr-6 flex flex-col">
                  <label className="label-text text-xs">Action Assignee:</label>
                  <select
                    className="text-center md:text-left border border-[#00aeef] text-gray-500 rounded-md focus:ring-orange-500 focus:border-orange-500"
                    {...register("actionBy")}
                  >
                    <option value="">Select an Agent</option>
                    {employeeNames.map((name) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="md:mr-6 flex flex-col">
                  <label className="label-text text-xs">Action Date:</label>
                  <input
                    type="date"
                    className="text-center md:text-left border border-[#00aeef] text-gray-500 rounded-md focus:ring-orange-500 focus:border-orange-500"
                    {...register("actionDate")}
                  />
                </div>
                <div className="md:mr-2 flex flex-col">
                  <label className="label-text text-xs mt-1">Action Type:</label>
                  <select className="text-xs w-[80px] border border-[#00aeef] text-gray-500 rounded-md focus:ring-orange-500 focus:border-orange-500" {...register("actionType")}>
                    <option value="">Select an action type</option>
                    {['Call', 'Email', 'Meeting'].map((type, i) => (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col mt-4">
                  <label className="flex items-center space-x-2">
                    <input type="checkbox" checked={localStatus === 'COMPLETED'} onChange={handleCheckboxChange} />
                    <span className="text-xs font-bold">Task Complete</span>
                  </label>
                </div>
              </div>
            )}
            <div className="relative my-12 ml-4">
              <div className="absolute bottom-0 right-0 flex gap-1">
                <button type="submit" className="btn btn-xs hover:bg-green-400">
                  {savedSuccessfully ? '✓' : 'Save'}
                </button>
                <button type="button" className="btn btn-xs hover:bg-red-400" onClick={() => onSubmit({ delete: true })}>Delete</button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default NoteAddEdit;
