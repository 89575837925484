import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setEmployer } from '../store/postsSlice';

const AUNZEmployers = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!data || Object.keys(data).length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div className="bg-amber-500 p-6 rounded-lg shadow-black shadow-sm">
      {Object.entries(data).map(([country, employersArray], i) => {
        // Create a new sorted array without modifying the original employersArray
        const sortedEmployers = [...employersArray].sort((a, b) => {
          const jobsToBeLoadedA = a.approxJobQtyWebsite > a.job_count ? a.approxJobQtyWebsite - a.job_count : 0;
          const jobsToBeLoadedB = b.approxJobQtyWebsite > b.job_count ? b.approxJobQtyWebsite - b.job_count : 0;
          return jobsToBeLoadedB - jobsToBeLoadedA;
        });

        return (
          <div key={i} className="mb-8">
            <h3 className="text-gray-600 font-bold text-lg mb-2 underline">{country}</h3>
            <div className="grid grid-cols-6 gap-4">
              <div className="col-span-2 font-bold">Company</div>
              <div className="col-span-1 font-bold">No. listed on AJ (sales leads)</div>
              <div className="col-span-1 font-bold">No. of jobs on company website</div>
              <div className="col-span-1 font-bold">Jobs to be loaded</div>
              <div className="col-span-1 font-bold">Total Sales Leads</div>

            </div>
            <div className="bg-white border rounded-xl mb-2 p-2">
              {sortedEmployers.map(({ id, company_name, job_count, approxJobQtyWebsite, logo , salesLeads_count, total_salesLeads_count}, j) => (
                <div key={j} className="grid grid-cols-6 gap-4 mb-2">
                  <div className="col-span-2">
                    <span
                      className="text-gray-600 hover:underline hover:text-blue-500 cursor-pointer"
                      onClick={() => {
                        dispatch(setEmployer({ employer_id: id, company_name, logo }));
                        navigate(`/employers-center/${id}`);
                      }}
                    >
                      {company_name}
                    </span>
                  </div>
                  <div className="col-span-1">
                    {job_count} (<span style={{ color: 'red' }}>{salesLeads_count}</span>)
                  </div>  
                  <div className="col-span-1">{approxJobQtyWebsite > 0 ? approxJobQtyWebsite : "N/A"}</div>
                  <div className="col-span-1">{approxJobQtyWebsite > job_count ? approxJobQtyWebsite - job_count : "N/A"}</div>
                  <div className="col-span-1">{total_salesLeads_count}</div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AUNZEmployers;
