import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const JobList1 = ({ endOfJobs, data, nextPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showCurrentJobs, setShowCurrentJobs] = useState(true);
  const [showExpiredJobs, setShowExpiredJobs] = useState(false);
  const handleEditJob = (job) => {
    navigate("/post-job-partner/", { state: { job } });
  };

  // Check if data is defined and is an array, then filter
  const filteredData = Array.isArray(data) ? data.filter(job => {
    const isExpired = new Date(job.expiration_date) < new Date();
    if (showCurrentJobs && !isExpired) return true;
    if (showExpiredJobs && isExpired) return true;
    return false;
  }) : [];

  const jobCount = filteredData.length;

  let content;

  // Check if filteredData is defined and is an array
  if (jobCount > 0) {
    content = filteredData.map((job, index) => {
      const {
        headlineOnly,
        email1,
        activation_date,
        createdAt,
        activeYN,
        employer_id,
        title,
        location,
        expiration_date,
        how_to_apply,
        description,
        featured,
        id,
        company_name1,
      } = job;

      return (
        <div
          className={`bg-white border border-gray-200 p-4 mb-4 rounded-xl shadow-lg block font-bold ${index % 2 === 0 ? '' : ''}`}
          key={index}
        >

          <div className='flex justify-between mb-2'>
            <div className="flex items-center pb-2">
              <Link
                className=""
                to={`https://iloveacademicjobs.com/jobs/myjob/${id}/`}
              >
                <div className="flex-1">
                  <span className="block text-gray-500 text-xl font-bold leading-tight hover:underline cursor-pointer">
                    {title}
                  </span>
                </div>
              </Link>

            </div>
            <div className="w-full sm:w-auto">
              <button className="btn text-sky-600 hover:underline text-xs cursor-pointer w-full sm:w-auto" onClick={() => handleEditJob(job)}>
                Edit Job
              </button>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="text-sm text-gray-700 font-light">{company_name1}</div>
            <div className="text-sm text-gray-700 font-light">{location}</div>
          </div>
          <div className="flex justify-between items-center">
            <div className="text-sm text-gray-700 font-light">
              {new Date(activation_date).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </div>
            <div className="w-[300px] text-sm text-amber-500 font-light">
              {featured ? <span>Featured</span> : <span>Not Featured</span>}
            </div>

            <div className="text-gray-700 text-sm font-light">
              {((new Date(expiration_date) < new Date()) && expiration_date) ? (
                <div className="bg-opacity-50 bg-red-500">Expired</div>
              ) : (
                <time>
                  {expiration_date &&
                    `Exp. ${new Date(expiration_date).toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                    })}`}
                </time>
              )}
            </div>
          </div>

        </div>
      );
    });
  } else {
    content = <div>Please select which jobs you would like to see above!</div>;
  }

  return (
    <div className="overflow-y w-full">
      <div className="flex mb-4">
        <label className="mr-4">
          <input
            type="checkbox"
            checked={showCurrentJobs}
            onChange={() => setShowCurrentJobs(!showCurrentJobs)}
            className='mr-2'
          />
          Current Jobs
        </label>
        <label>
          <input
            type="checkbox"
            checked={showExpiredJobs}
            onChange={() => setShowExpiredJobs(!showExpiredJobs)}
            className='mr-2'
          />
          Expired Jobs
        </label>
        <div className="ml-4 mb-4 text-gray-700 font-bold">
          Job Count: {jobCount}
        </div>
      </div>

      {/* Job Count Display */}


      {content}

    </div>
  );
};

export default JobList1;
