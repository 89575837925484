import React, { useState, useEffect } from "react";
import {
    useAddOtherContactsMutation,
} from "../store/apiSlice";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import InputBlock5 from './InputBlock5';

const AddOtherContacts = ({ id }) => {
    console.log("=====AddOtherContacts==========", id);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [logo, setLogo] = useState('');
    useEffect(() => {
        return () => { };
    }, []);

    const [AddOtherContacts1] = useAddOtherContactsMutation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        console.log("data", { ...data, employer_id: id });
        if (data) AddOtherContacts1({ ...data, employer_id: id });
    };

    return (
        <div className="overflow-y w-full">
            <form className="grid grid-cols-1 gap-4" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <InputBlock5
                        type="text"
                        field="firstName"
                        label="First Name"
                        register={register}
                        errors={errors}
                    />
                </div>
                <div>
                    <InputBlock5
                        type="text"
                        field="lastName"
                        label="Last Name"
                        register={register}
                        errors={errors}
                    />
                </div>
                <div>
                    <InputBlock5
                        type="text"
                        field="title"
                        label="Title"
                        register={register}
                        errors={errors}
                    />
                </div>
                <div>
                    <InputBlock5
                        type="text"
                        field="email"
                        label="Email"
                        register={register}
                        errors={errors}
                    />
                </div>
                <div>
                    <InputBlock5
                        type="text"
                        field="phone"
                        label="Phone"
                        register={register}
                        errors={errors}
                    />
                </div>

                <div className="flex space-x-4">
                    <label className="mb-2">Contact Role: </label>

                    <label><input type="checkbox" className="mr-1" {...register("hrYN")} />HR </label>

                    <label><input type="checkbox" className="mr-1" {...register("recruiterYN")} />Recruiter </label>

                    <label><input type="checkbox" className="mr-1" {...register("departmentLeadYN")} />Department Lead </label>

                </div>

                {/* Radio buttons for contactType */}
                <div className="flex justify-between">
                    <div className="space-x-4">
                        <label className="mb-2"> Contact Type: </label>
                        <label>
                            <input
                                type="radio"
                                value="MAIN"
                                {...register("contactType")}
                                className="mr-1" 
                            />
                            Main
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="SECONDARY"
                                {...register("contactType")}
                                className="mr-1" 
                            />
                            Secondary
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="OTHER"
                                {...register("contactType")}
                                className="mr-1" 
                            />
                            Other
                        </label>
                    </div>
                    <div className="">
                        <button type="submit" className="btn-md text-white bg-green-500 rounded-lg">Save</button>
                    </div>
                </div>


            </form>
        </div>
    );
};

export default AddOtherContacts;
