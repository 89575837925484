import React, { useState, useEffect, useRef, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import Select from 'react-select';
import {
  useGetCampusesMutation,
  useGetEmployerSuggestionsQuery,
  usePostAJobMutation,
  useGetEmployerQuery,
  useUpdateEmployerMutation,
  useUpdateJobMutation,
  useCreateEmployerMutation,
  useSendEmailMutation
} from "../store/apiSlice";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import InputBlock2 from "./InputBlock2";
import JobKeywordSearchBlock from "./JobKeywordSearchBlock";
import SelectBlock2 from "./SelectBlock2";
import SelectBlock from "./SelectBlock";
import UniSearchBlock from "./UniSearchBlock";
import SelectBlockNew from "./SelectBlockNew";
import SelectBlock1 from "./SelectBlock1";
import job_category from "../data/JobCategories1.json";
import { countries } from "../utils/data";
import { currencies } from "../utils/data";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { setEmployer } from "../store/postsSlice";
import { useSendEmail3Mutation } from "../store/apiSlice";
import ImageResize from 'quill-image-resize-module-react';
import CreatePartnerInvoice from "../components/CreatePartnerInvoice";
import { useCreateInvoiceMutation } from "../store/apiSlice";
import PaymentModal from "../components/PaymentModal";  // Import your PaymentModal component
import swal from 'sweetalert';
import { academic_positions } from "../data/AcademicPositionTypes";

const validationSchema = yup
  .object({
    // Your validation schema
  })
  .required();

const JobAddEdit = () => {
  const location = useLocation();
  const job = location.state?.job || null;
  const [createInvoice] = useCreateInvoiceMutation();
  const [sendEmail] = useSendEmailMutation();
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false); // Payment modal state
  const [jobData, setJobData] = useState(null); // Store job data temporarily before posting

  const isAddMode = !job || Object.keys(job).length === 0;

  console.log(job);

  const [secondCategory, setSecondCategory] = useState(job?.thirdcategory ?? null);
  window.Quill = Quill
  Quill.register('modules/imageResize', ImageResize);
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };
  const formats = ['image'];
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const employer = useSelector((state) => state.posts.employer);
  const [startTime, setStartTime] = useState(Date.now());
  let defaultValues;
  let buttonText;
  const {
    control,
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  const extractReferenceNumber = (url) => {
    const parts = url.split('/');
    return parts[parts.length - 1];
  };

  let master_category_job_type = watch("master_category_job_type");

  useEffect(() => {
    if (!job) {
      buttonText = "Post Job";
      reset({
        salary_period: "yearly",
        currency: "USD",
        activation_date: new Date().toISOString().substring(0, 10),
        expiration_date: new Date(
          new Date().getTime() + 30 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .substring(0, 10),
        remote: "Onsite",
        position_type: "",
      });
      setValue("featured", true);
      setValue("headlineOnly", false);
      master_category_job_type = "Academic / Faculty";
      setValue("master_category_job_type", "Academic / Faculty");
    } else {
      buttonText = "Update Job";
      master_category_job_type = job.master_category_job_type;
      reset(job);
      setValue(
        "activation_date",
        new Date(job.activation_date).toISOString().split("T")[0]
      );
      setValue(
        "expiration_date",
        new Date(job.expiration_date).toISOString().split("T")[0]
      );
      dispatch(
        setEmployer({
          company_name: job?.employer?.company_name,
          employer_id: job.employer_id,
          logo: job.employer?.logo,
          employerPageURL: job.employer?.employerPageURL,
          clientType: job.clientType,
        })
      );
    }
  }, [job]);

  useEffect(() => {
    register("description", { required: false });
    register("company_name1", { required: false });
  }, [register]);

  useEffect(() => {
    if (!job) {
      setValue("company_name1", employer?.company_name);
      setValue("employer_id", employer?.employer_id);
    }
  }, [employer]);

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyCKEfoOIPz8l_6A8BByD3b3-ncwza8TNiA",
    options: {
      componentRestrictions: { country: "Australia" },
    },
    onPlaceSelected: (place) => {
    },
  });

  const jobStatusDefault = job ? "Update Job" : "Post and Pay";
  let content;
  const [jobStatus, setJobStatus] = useState(jobStatusDefault);
  const editorContent = watch("description");
  const editorContent1 = watch("employer_name");
  const [query, setQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(true);
  const inputRef = useRef();
  const submitRef = useRef();
  const countryRef = useRef("");
  const { data: suggestions = [] } = useGetEmployerSuggestionsQuery(
    { query, country: countryRef.current || "" },
    {}
  );

  const [
    postAJob,
    {
      data: dataPostAJob,
      isLoading: isLoadingPostAJob,
      isSuccess: isSuccessPostAJob,
      isError: isErrorPostAJob,
      error: errorPostAJob,
    },
  ] = usePostAJobMutation();
  const [updateJob, { isLoading: isLoadingUpdateJob, isSuccess: isSuccessUpdateJob, isError: isErrorUpdateJob, error: errorUpdateJob }] = useUpdateJobMutation();

  const [
    createEmployer,
    {
      data,
      isLoading: isLoadingCreateEmployer,
      isSuccess: isSuccessCreateEmployer,
      isError: isErrorCreateEmployer,
      error: errorCreateEmployer,
    },
  ] = useCreateEmployerMutation();

  async function updateJob1(data) {
    setJobStatus("Updating Job...");
    const response = await updateJob(data);
    if (response) {
      setJobStatus("All Done!");
      setTimeout(() => {
        setJobStatus("Update Job");
      }, 10000);
    }
  }

  const company_name = useSelector((state) => state.auth.company_name);
  const job_type = ["Full time", "Part time", "Contractor", "Sessional"];

  const onEditorStateChange = (editorState) => {
    setValue("description", editorState);
  };

  const onEditorStateChange1 = (
    company_name,
    employer_id,
    Region,
    country,
    logo,
    employerPageURL,
    clientType
  ) => {
    setValue("company_name1", company_name);
    setValue("employer_id", employer_id || 0);
    setValue("Region", Region);
    setValue("country", country);
    setValue("logo", logo);
    dispatch(
      setEmployer({
        company_name,
        employer_id,
        logo,
        employerPageURL,
        clientType,
      })
    );
    if (employer_id) getCampuses({ id: employer_id });
  };

  const [getCampuses, { data: dataCampuses }] = useGetCampusesMutation();

  const academicPositionOptions = Object.entries(academic_positions).flatMap(
    ([category, positions]) =>
      positions.map((position) => ({
        label: `${category}: ${position}`,
        value: position,
      }))
  );

  const academicPositionDefaultValues = job?.positiontypes?.length
    ? job.positiontypes.map((p) => ({
      label: `${p.category}: ${p.positionType}`,
      value: p.positionType,
    }))
    : [];

    const onSubmit = async (data) => {
      if (!data.location) {
        alert('Please add a location.');
      } else {
        const referenceNumber = extractReferenceNumber(data.how_to_apply);
    
        setJobData({
          ...data,
          jobPostDuration: Math.ceil((Date.now() - startTime) / 1000),
          postedBy: userInfo.id,
          referenceNumber, // Add the extracted reference number to jobData
        });

        // Check if employer_id is 0 and create a new employer if needed
        if (data.employer_id === 0) {
          try {
            const newEmployerResponse = await createEmployer({
              company_name: data.company_name1,
            });
    
            if (newEmployerResponse && newEmployerResponse.data) {
              data.employer_id = newEmployerResponse.data.employer_id; // Use the newly created employer_id
            } else {
              throw new Error("Failed to create employer");
            }
          } catch (error) {
            console.error("Error creating employer:", error);
            swal("Error!", "There was an issue creating the employer. Please try again.", "error");
            return; // Stop submission if employer creation failed
          }
        }
    
        if (isAddMode) {
          setPaymentModalOpen(true); // Open payment modal to collect payment details
        } else {
          updateJob1({ id: job?.id, ...data });
        }
      }
    };
    

  const handlePaymentSubmit = async (paymentDetails) => {
    try {
      // Show the loading alert
      swal({
        title: "Processing...",
        text: "Please wait while your invoice is being created and your job is being posted.",
        icon: "info",
        buttons: false, // Disable buttons during loading
        closeOnClickOutside: false, // Prevent closing on outside click
        closeOnEsc: false, // Prevent closing on ESC key
        content: {
          element: "div",
          attributes: {
            innerHTML: '<div class="loading-circle"></div>',
          },
        },
      });

      const invoiceData = {
        ...jobData, // Use the job data stored in state
        ...paymentDetails,
        ...userInfo
      };

      const postJobData = {
        ...jobData
      };

      await CreatePartnerInvoice(invoiceData, createInvoice, sendEmail); // Create the invoice
      const { data: jobResponse } = await postAJob(postJobData); // Post the job after payment details are submitted

      // Close the loading alert
      swal.close();

      // Notify the user of success
      swal("Success!", "Your invoice was created and the job was posted successfully.", "success");

      setJobStatus("Job posted");
    } catch (error) {
      // Close the loading alert in case of an error
      swal.close();

      // Notify the user of an error
      swal("Error!", "There was an issue with job posting or invoice creation. Please try again.", "error");

      console.error('Error during job posting or invoice creation:', error);
    }
  };
  content = (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full md:flex flex-row justify-between gap-8"
      >
        <div className="w-full md:w-1/2 flex flex-col gap-2">
          <div className="flex justify-start gap-1">
            {!job &&
              <ul className=" flex justify-start items-end gap-4   px-1  ">
                <div className="card bg-base-100 border border-1 p-2 border-slate-300 w-[100px] h-[100px] grid place-items-center">
                  <img
                    src={
                      employer?.logo
                        ? `https://academicjobs.s3.amazonaws.com/img/university-logo/${employer?.logo}`
                        : "/favicon.png"
                    }
                    alt={`${company_name}`}
                    className="object-contain rounded-md bg-white"
                  />
                </div>
              </ul>
            }
            <div className=" w-[100%]">
              <div className=" w-[100%] mx-auto flex flex-col gap-1 ">
                <label className="label-text font-bold pb-2  text-lg">
                  Institution Name
                </label>
                <UniSearchBlock
                  register={register}
                  field="employer_name"
                  customKey="employer_name"
                  value1={job?.company_name1}
                  forceClass=""
                  onChange={onEditorStateChange1}
                  label="Start typing institution name and select from dropdown"
                />
              </div>
            </div>
          </div>
          <label className="label-text  text-lg mt-4 font-bold">
            Job Title
          </label>
          <InputBlock2
            type="text"
            field="title"
            label=""
            register={register}
            errors={errors}
            forceClass=""
          />
          <div className=" w-[100%] mx-auto flex justify-start items-start  gap-1 ">
            <input type="checkbox" className="mt-1" {...register("featured")} />
            <label className="label-text text-sm">Featured (This job can be featured at no extra cost)</label>
          </div>
          <div className="mb-6 mt-4">
            <label className="label-text  pb-2  text-lg mt-4 font-bold">
              Job Description
            </label>
            <ReactQuill
              modules={modules} theme="snow"
              className="job_post_editor w-full mb-6"
              value={editorContent}
              onChange={onEditorStateChange}
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 flex flex-col">
          <label className="label-text font-bold text-lg">Job Specialty</label>
          <JobKeywordSearchBlock
              field="Enter a keyword"
              value1={job?.thirdcategory}
              customKey="Enter a keyword"
              label="Enter a keyword"
              forceClass="mb-6"
              onSelect={(specialty_name, subcategory_name, subcategory_column, master_category_name) => {
                setValue("master_category_job_type", master_category_name);
                setValue(subcategory_column, subcategory_name)
                setSecondCategory(subcategory_name)
                setValue("thirdcategory", specialty_name)
              }}
              //onInputChange={onInputChange}
              className="w-full"
            />
          <div
            className={`flex flex-col gap-2 mb-6 mt-6  ${master_category_job_type === "Academic / Faculty"
              ? "block"
              : "hidden"
              }`}
          >
            <label className="label-text font-bold text-lg">
              Academic Position Types:
            </label>
            <Select
              isMulti
              options={academicPositionOptions}
              defaultValue={academicPositionDefaultValues}
              className="basic-multi-select"
              classNamePrefix="select"
              name="positiontypes"
              onChange={(selectedOptions) => {
                // Set an empty array if no options are selected
                setValue(
                  "positiontypes",
                  selectedOptions && selectedOptions.length > 0
                    ? selectedOptions.map((option) => option.value)
                    : [] // Send an empty array if nothing is selected
                );
              }}
            />
          </div>
          <SelectBlock2
            list={job_type}
            field="job_type"
            label="Employment Type"
            register={register}
            errors={errors}
            forceClass=" font-bold"
          />
          <div className="label">
            <span className="label-text  pb-2  text-lg font-bold mb-[-10px]">
              Job Location
            </span>
          </div>
          <div>
            <Autocomplete
              className="border p-1 rounded-lg mt-2 "
              style={{ width: "100%" }}
              apiKey="AIzaSyCKEfoOIPz8l_6A8BByD3b3-ncwza8TNiA"
              {...register("location1")}
              onPlaceSelected={(place) => {
                const addressComponents = place.address_components || [];
                let city = "";
                let state = "";
                let country1 = "";
                let postalCode = "";
                addressComponents.forEach((component) => {
                  const types = component.types || [];
                  if (types.includes("locality")) {
                    city = component.long_name;
                  } else if (types.includes("administrative_area_level_1")) {
                    state = component.long_name;
                  } else if (types.includes("country")) {
                    country1 = component.long_name;
                  } else if (types.includes("postal_code")) {
                    postalCode = component.long_name;
                  }
                });
                setValue("location", place.formatted_address);
                setValue("city", city);
                setValue("state", state);
                setValue("country", country1);
                setValue("region", country1);
                setValue("postalCode", postalCode);
              }}
              options={{
                types: ["geocode", "establishment"],
              }}
              onChange={(e) => setValue("location", e.target.value)}
              onError={(status, clearSuggestions) => {
                console.error("Google Maps API returned error with status: ", status);
                clearSuggestions();
              }}
              debounce={300}
            />
            <div>{job?.location}</div>
          </div>
          <div className="flex items-center justify-start mt-4">
            <div className="form-control items-start mb-2 mr-4">
              <label className="flex items-start justify-start label cursor-pointer">
                <input
                  {...register("remote")}
                  type="radio"
                  className="radio radio-warning radio-xs mr-1"
                  value="Onsite"
                />
                <span className="label-text font-bold pb-2  text-sm">
                  Onsite
                </span>
              </label>
            </div>
            <div className="form-control items-start mb-2 mr-4">
              <label className="flex items-start justify-start label cursor-pointer">
                <input
                  {...register("remote")}
                  type="radio"
                  className="radio radio-warning radio-xs mr-1"
                  value="Remote"
                />
                <span className="label-text font-bold pb-2  text-sm">
                  Remote
                </span>
              </label>
            </div>
            <div className="form-control items-start mb-2">
              <label className="flex items-start justify-start label cursor-pointer">
                <input
                  {...register("remote")}
                  type="radio"
                  className="radio radio-warning radio-xs mr-1"
                  value="Hybrid"
                />
                <span className="label-text font-bold pb-2  text-sm">
                  Hybrid
                </span>
              </label>
            </div>
          </div>
          <div className="label">
            <span className="label-text  pb-2  text-lg font-bold mb-[-10px]">
              Salary Range
            </span>
          </div>
          <div className="join flex mb-6 gap-2">
            <div className="max-w-[80px]">
              <div>
                <SelectBlock2
                  list={currencies}
                  field="currency"
                  label="Currency"
                  register={register}
                  errors={errors}
                  forceClass="join-item rounded-r-none min-h-[34px]"
                />
              </div>
            </div>
            <div className="flex-grow">
              <InputBlock2
                type="text"
                field="salary_from"
                label="$From"
                register={register}
                errors={errors}
                forceClass="rounded-none min-h-[34px]"
              />
            </div>
            <div className="flex-grow">
              <InputBlock2
                type="text"
                field="salary_to"
                label="$To"
                register={register}
                errors={errors}
                forceClass="rounded-none min-h-[34px]"
              />
            </div>
            <div className="max-w-[100px]">
              <div>
                <SelectBlock2
                  list={["yearly", "monthly", "weekly", "daily", "hourly"]}
                  field="salary_period"
                  label="Frequency"
                  register={register}
                  errors={errors}
                  forceClass="join-item rounded-l-none min-h-[34px]"
                />
              </div>
            </div>
          </div>
          <label className="label-text font-bold pb-2  text-lg">
            How To Apply (Listing URL)
          </label>
          <InputBlock2
            type="text"
            field="how_to_apply"
            label=""
            register={register}
            errors={errors}
            forceClass=""
          />
          <div className="grid grid-cols-2 gap-4 mb-4 mt-8">
            <InputBlock2
              type="date"
              field="activation_date"
              label="Post Date"
              register={register}
              errors={errors}
              forceClass="mb-6 font-bold text-xl"
            />
            <InputBlock2
              type="date"
              field="expiration_date"
              label="Expiration Date"
              register={register}
              errors={errors}
              forceClass="mb-6 font-bold"
            />
          </div>

          <div className="flex justify-between items-center gap-1 p-6 bg-white border border-gray-200 rounded-lg shadow-lg">
            {!job &&
              <div className="items-center">
                <p className="text-lg text-gray-400 mr-2">Normal price for single job ad: $315 </p>
                <p className="text-lg text-gray-400 mr-2">Reseller commission: $63 (20%) </p>
                <p className="text-3xl font-bold text-green-500">You pay: $252</p>
                <p className="text-xs font-light text-green-500">All pricing in USD</p>
              </div>
            }
            <button type="submit" className="btn btn-success" ref={submitRef}>
              {jobStatus}
            </button>
          </div>

        </div>
      </form>
      {isPaymentModalOpen && (
        <PaymentModal
          userInfo={userInfo}
          onClose={() => setPaymentModalOpen(false)}
          onSubmit={handlePaymentSubmit}
          initialInvoiceReference={jobData?.referenceNumber || ''}
        />
      )}
    </>
  );

  return <div className="overflow-y w-full p-6">
    <p className="text-3xl font-bold text-black shadow-xl px-2 pb-4 mb-6">Post A Job</p>
    {content}
  </div>;
};

export default JobAddEdit;
