import React, { useState } from 'react';
import PropTypes from 'prop-types';

const PaymentModal = ({ userInfo, onClose, onSubmit, initialInvoiceReference  }) => {
    // Customer Details
    const [firstName, setFirstName] = useState(userInfo.firstName || '');
    const [lastName, setLastName] = useState(userInfo.lastName || '');
    const [email, setEmail] = useState(userInfo.firstName ? userInfo.email : '');

    // Invoice Details
    const [addressLine1, setAddressLine1] = useState(userInfo.location || '');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState(userInfo.city || '');
    //   const [region, setRegion] = useState(userInfo.location || '');
    const [postalCode, setPostalCode] = useState(userInfo.postCode || '');
    const [state, setState] = useState(userInfo.state || '');
    const [country, setCountry] = useState(userInfo.country || '');
    const [invoiceReference, setInvoiceReference] = useState(initialInvoiceReference  || '');

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const paymentDetails = {
            '01_First_Name': firstName,
            '01_Last_Name': lastName,
            '02_Email': email,
            'address_line1': addressLine1,
            'address_line2': addressLine2,
            city,
            state,
            //   region,
            postalCode,
            country,
            '08_Invoice_Reference': invoiceReference,
        };
        onSubmit(paymentDetails); // Pass payment details back to parent component
        onClose(); // Close the modal
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-[9999]">
            <div className="bg-white p-6 rounded shadow-lg max-w-3xl w-full z-[10000] relative">
                <h2 className="text-2xl font-bold mb-4">Enter Payment Details (Invoicing)</h2>
                
                <form onSubmit={handleFormSubmit}>
                    {/* Customer Details */}
                    <div className="flex flex-wrap -mx-2 mb-4">
                        <div className="w-1/3 px-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
                                First Name
                            </label>
                            <input
                                type="text"
                                id="firstName"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                required
                            />
                        </div>
                        <div className="w-1/3 px-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
                                Last Name
                            </label>
                            <input
                                type="text"
                                id="lastName"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                required
                            />
                        </div>
                        <div className="w-1/3 px-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                required
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-2 mb-4">


                    </div>

                    {/* Invoice Details */}
                    <div className="flex flex-wrap -mx-2 mb-4">
                        <div className="w-1/2 px-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="addressLine1">
                                Address Line 1
                            </label>
                            <input
                                type="text"
                                id="addressLine1"
                                value={addressLine1}
                                onChange={(e) => setAddressLine1(e.target.value)}
                                className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                required
                            />
                        </div>
                        <div className="w-1/2 px-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="addressLine2">
                                Address Line 2
                            </label>
                            <input
                                type="text"
                                id="addressLine2"
                                value={addressLine2}
                                onChange={(e) => setAddressLine2(e.target.value)}
                                className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-2 mb-4">
                        <div className="w-1/2 px-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="city">
                                City
                            </label>
                            <input
                                type="text"
                                id="city"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                required
                            />
                        </div>
                        <div className="w-1/2 px-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="postalCode">
                                Postal Code
                            </label>
                            <input
                                type="text"
                                id="postalCode"
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                required
                            />
                        </div>
                        {/* <div className="w-1/2 px-2">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="region">
                State/Region
              </label>
              <input
                type="text"
                id="region"
                value={region}
                onChange={(e) => setRegion(e.target.value)}
                className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div> */}
                    </div>
                    <div className="flex flex-wrap -mx-2 mb-4">
                        <div className="w-1/2 px-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="invoiceReference">
                                State
                            </label>
                            <input
                                type="text"
                                id="state"
                                value={state}
                                onChange={(e) => setInvoiceReference(e.target.value)}
                                className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                required
                            />
                        </div>
                        <div className="w-1/2 px-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="country">
                                Country
                            </label>
                            <input
                                type="text"
                                id="country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                required
                            />
                        </div>

                    </div>
                    <div className="flex flex-wrap -mx-2 mb-4">
                        <div className="w-full px-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="invoiceReference">
                                Job Reference Number (for Invoice)
                            </label>
                            <input
                                type="text"
                                id="invoiceReference"
                                value={invoiceReference}
                                onChange={(e) => setInvoiceReference(e.target.value)}
                                className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                required
                            />
                        </div>
                    </div>

                    <div className="flex justify-end">
                        <button
                            type="button"
                            className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white py-2 px-4 rounded"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
            <div className="fixed inset-0 bg-black opacity-50 z-[9998]" onClick={onClose}></div>
        </div>
    );
};

PaymentModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialInvoiceReference : PropTypes.string, // Add this line
};


export default PaymentModal;
