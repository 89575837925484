import React, { useState } from 'react';
import searches from "../utils/searchQueries.json";
import generalSearches from "../utils/GeneralSearchQueries.json";

const KeywordSearches = ({ userId }) => {

    const searchQuery = searches.filter(search => search.id === userId).map(search => search.query);;

    return (
        // <div className="pb-16 custom-scrollbar rounded-xl max-h-[500px] overflow-y-auto border whitespace-pre-wrap bg-white p-3 mb-4">
        <div className="pb-16 ml-8">
            <span className="font-bold underline">General Searches</span>
            <ul className="list-disc mb-8">
                {
                    generalSearches.map((link, index) => (
                        <li className="pb-1" key={index}>
                            <a className="text-sky-600 hover:text-gray-600" href={`https://www.google.com/search?q=${encodeURIComponent(link)}`} target="_blank" rel="noopener noreferrer">{link}</a>
                        </li>
                    ))
                }
            </ul>
            <span className="font-bold underline">Specific Assigned Searches</span>
            <ul className="list-disc">
                {searchQuery.length > 0 ? (
                    searchQuery.map((link, index) => (
                        <li className="pb-1" key={index}>
                            <a className="text-sky-600 hover:text-gray-600" href={`https://www.google.com/search?q=${encodeURIComponent(link)}`} target="_blank" rel="noopener noreferrer">{link}</a>
                        </li>
                    ))
                ) : (
                    <span className="pb-1">No specific searches assigned</span>
                )}
            </ul>
        </div>
    );
};
export default KeywordSearches;

