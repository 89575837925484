// src/DashBoard.js
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetJobs3Query } from "../store/apiSlice";
import CountUp from "react-countup";
import AcademicConnectPromo from "../components/AcademicConnectPromo";
import { useNavigate } from "react-router-dom";
import ACLogo from "../components/ACLogo";
import ContactCard from "../components/ContactCard";
import { FaInfoCircle } from "react-icons/fa"; // Import info icon from react-icons

const DashBoard = () => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [q] = useState({
    userId: -1,
    employer_id: userInfo?.employer_id || -1,
  });
  const { data, error, isLoading } = useGetJobs3Query({ ...q });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-xl font-semibold">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-center">
          <p className="text-lg">
            No job data to load, start posting now{" "}
            <a
              className="text-amber-500 hover:text-gray-500 underline"
              href="/post-job-employer"
            >
              here
            </a>
            !
          </p>
        </div>
      </div>
    );
  }

  const totalJobs = data?.length || 0;
  const totalHeadlineJobs =
    data?.filter((job) => job.headlineOnly === 1).length || 0;
  const totalFullJobs =
    totalJobs - totalHeadlineJobs || 0;
  const totalFeaturedJobs =
    data?.filter((job) => job.featured === 1).length || 0;
  const totalAcademicConnections = data?.reduce(
    (total, job) => total + (job?.academic_connections_count || 0),
    0
  );

  const linkState = `underline hover:text-amber-500`;
  const domain = `https://www.academicjobs.com/`;

  const employerAbbrev = userInfo.employer_id === 3786 ? "QUT" : "";

  return (
    <div className="px-4">
      <h2 className="text-gray-blue font-bold text-4xl mt-8">Live Job Stats</h2>
      <div
        className={`grid grid-cols-1 gap-6 mt-8 md:grid-cols-2 lg:grid-cols-4`}
      >

        <Card
          title="Full Jobs"
          count={totalFullJobs}
          navigateTo={"/jobs-center1"}
        />
        <Card
          title="AcademicConnect Referrals"
          count={totalAcademicConnections}
          iconSrc={"/icons/network-icon.svg"}
          navigateTo={"/academic-connect"}
        />
        <Card
          title="Headline Jobs"
          count={totalHeadlineJobs}
          navigateTo={"/jobs-center1"}
          info={
            <>
              Shows the &lsquo;Job Title&rsquo; only&hellip; as it is not yet
              activated as a Full Job.
              Upgade to make this job a full listing for just $315{" "}

              and access <ACLogo /> for free!
            </>
          }
        />
        <Card
          title="Total Jobs"
          count={totalJobs}
          navigateTo={"/jobs-center1"}
        />
        {/* QUT Stats */}
        {userInfo.employer_id === 3786 ? (
          <>
            <InfoCard
              title={`${employerAbbrev} Average Weekly Job Views`}
              textSize={"text-6xl"}
              count={19216}
            />
            <InfoCard
              title={`${employerAbbrev} Average Weekly Job Apply Hits`}
              textSize={"text-6xl"}
              count={19}
            />
            <InfoCard
              title={`${employerAbbrev} Average Weekly Job Alerts`}
              textSize={"text-6xl"}
              count={15021}
            />
            <InfoCard
              title={`${employerAbbrev} Social Reach`}
              imgSrc="/employer-dashboard/social-icons.png"
              width={500}
              height={100}
              textSize={"text-6xl"}
              count={26423}
            />
          </>
        ) : null}
        {/* AUT Stats */}
        {userInfo.employer_id === 3804 ? (
          <>
            <InfoCard
              title={`${employerAbbrev} Average Weekly Job Views`}
              textSize={"text-6xl"}
              count={3603}
            />
            <InfoCard
              title={`${employerAbbrev} Average Weekly Job Apply Hits`}
              textSize={"text-6xl"}
              count={14}
            />
            <InfoCard
              title={`${employerAbbrev} Average Weekly Job Alerts`}
              textSize={"text-6xl"}
              count={9793}
            />
            <InfoCard
              title={`${employerAbbrev} Social Reach`}
              imgSrc="/employer-dashboard/social-icons.png"
              width={500}
              height={100}
              textSize={"text-6xl"}
              count={26423}
            />
          </>
        ) : null}
      </div>

      <AcademicConnectPromo forceClass="m-0 mt-16 mb-12" />

      <div className="mt-0">
        <h2 className="text-gray-blue font-bold text-4xl mb-8">Our Audience</h2>
        <div className={`grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4`}>
          <InfoCard
            title="Fastest Growing Job Board on Google"
            imgSrc="/employer-dashboard/g-logo.png"
            width={200}
            height={100}
            textSize={"text-sm"}
          />
          {/* {userInfo.region === "Australia" ? (
            <InfoCard
              title="#1 for Indigenous Academic Jobs"
              description="Academic Jobs is the only Jobs Board Globally that is dedicated to Indigenous Academic Jobs. The largest network of indigenous job seekers in higher education, with 1,200. Sign up to receive job alerts"
              imgSrc="/employer-dashboard/indigenous.png"
              width={200}
              height={100}
              textSize={"text-sm"}
            />
          ) : (
            <InfoCard
              title="#1 for Higher Ed Jobs"
              description="Academic Jobs is the only Jobs Board Globally that is dedicated to Indigenous Academic Jobs. The largest network of indigenous job seekers in higher education, with 1,200. Sign up to receive job alerts"
              imgSrc="/icons/globe.png"
              width={200}
              height={100}
              textSize={"text-sm"}
            />
          )} */}
          <InfoCard
            title="Social Reach"
            description="1.2 million +"
            imgSrc="/employer-dashboard/social-icons.png"
            width={500}
            height={100}
            textSize={"text-6xl"}
          />
          <InfoCard
            title="Used by Top 100 Ranked Universities"
            imgSrc="https://www.academicjobs.com/_next/image?url=%2Funiversity-logos%2Fmisc%2Funi-montage.webp&w=384&q=75"
            width={200}
            height={100}
            textSize={"text-sm"}
            forceImgClass="rounded-full"
          />
          <InfoCard
            title={<>{<ACLogo />} Your Network at Work</>}
            // title={<>{<ACLogo />} #1 Employee Referral Platform</>}
            imgSrc="https://www.academicjobs.com/academic-connect-logo.svg"
            width={500}
            height={100}
            textSize={"text-6xl"}
          />
          <InfoCard title="Average job views for featured jobs" stat="63" />
          <InfoCard
            title="Average apply now hits for featured jobs"
            stat="14.9"
          />
          <InfoCard
            title="Monthly job alerts"
            stat="541,632"
            subtext="3000% growth over 3 months"
          />
          <InfoCard
            title={<>{<ACLogo />} Benefits</>}
            stat="541,632"
            subtext="3000% growth over 3 months"
          >
            <div className="flex flex-col justify-between">
              <p className="text-sm italic leading-tight">
                Referred applicants are{" "}
                <a
                  target="_blank"
                  href={`${domain}/academic-connect`}
                  className={`font-bold text-[#735a7a] ${linkState}`}
                  rel="noreferrer"
                >
                  more qualified
                </a>
              </p>
              <p className="text-sm italic leading-tight">
                Referred candidates are{" "}
                <a
                  target="_blank"
                  href={`${domain}/academic-connect`}
                  className={`font-bold text-[#a66c98] ${linkState}`}
                  rel="noreferrer"
                >
                  hired faster
                </a>
              </p>
              <p className="text-sm italic leading-tight mb-0">
                Referred employees{" "}
                <a
                  target="_blank"
                  href={`${domain}/academic-connect`}
                  className={`font-bold text-[#b56576] ${linkState}`}
                  rel="noreferrer"
                >
                  stick around longer
                </a>
              </p>
            </div>
          </InfoCard>
        </div>
        <ContactCard personId="jarrod" forceClass="mt-10" />
      </div>
    </div>
  );
};

const Card = ({ title, count, iconSrc, navigateTo, info }) => {
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false); // State for tooltip visibility

  let firstWord = title.split(" ")[0];
  let secondWord = title.split(" ")[1];

  const isAcademicConnect = firstWord === "AcademicConnect";

  if (isAcademicConnect) {
    firstWord = <ACLogo />;
  }

  const handleClick = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  return (
    <div
      className={`relative border-[1px] cursor-pointer ${isAcademicConnect ? "border-amber-500" : "border-gray-300"
        } rounded-lg p-6 text-center shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl ${isAcademicConnect ? "hover:bg-amber-100" : ""
        }`}
      onClick={handleClick}
    >
      {isAcademicConnect && (
        <img
          src={iconSrc}
          alt="icon"
          className="absolute top-2 right-2 w-8 h-8"
        />
      )}

      {/* Info Icon with Tooltip */}
      {info && (
        <div
          className="absolute top-2 right-2 flex items-center"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <FaInfoCircle className="w-6 h-6 text-gray-500 cursor-pointer" />
          {showTooltip && (
            <div className="absolute right-[-6px] mt-[6.25rem] bg-white border border-gray-300 p-2 rounded shadow-md w-[200px] text-sm text-gray-700">
              {info}
            </div>
          )}
        </div>
      )}

      <h2 className="text-2xl font-semibold mb-4">
        <span className="block">{firstWord}</span>
        {secondWord && <span className="block">{secondWord}</span>}
      </h2>

      <p className="text-4xl font-bold">
        <CountUp end={count} duration={2.5} />
      </p>
    </div>
  );
};
const InfoCard = ({
  title,
  description = "",
  imgSrc = "",
  secondImg,
  borderColor = "border-gray-300",
  width,
  height,
  textSize,
  stat,
  children,
  forceImgClass = "",
  count = "",
}) => (
  <div
    className={`flex flex-col justify-between border items-center ${borderColor} rounded-lg p-3 text-center shadow-lg h-[204px]`}
  >
    <h2 className="text-2xl font-semibold m-0">
      {title}
      {description === "" ? null : (
        <>
          <br />
          {description}
        </>
      )}
    </h2>
    {imgSrc === "" ? (
      <>
        {children ? children : <p className="text-5xl font-bold m-0">{stat}</p>}
      </>
    ) : (
      <figure className="max-h-[90px] overflow-hidden my-2">
        <img
          src={imgSrc}
          alt={title}
          className={`w-full h-full object-cover ${forceImgClass}`}
        />
      </figure>
    )}
    {count !== "" ? (
      <p className="text-4xl font-bold">
        <CountUp end={count} duration={2.5} />
      </p>
    ) : null}
  </div>
);

export default DashBoard;
