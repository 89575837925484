import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import JobKeywordSearchBlock from "./JobKeywordSearchBlock";
export default function AHEIAComponent() {
  const cityAndUniversityLinks = [
    {
      name: `ACU Jobs`,
      link: `https://www.academicjobs.com/australian-catholic-university-acu-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20Australian%20Catholic%20University.png`,
    },
    {
      name: `ANU Jobs`,
      link: `https://www.academicjobs.com/australian-national-university-anu-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20Australian%20National%20University.png`,
    },
    {
      name: `Bond University Jobs`,
      link: `https://www.academicjobs.com/bond-university-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20bond_university_logo-australia.jpeg`,
    },
    {
      name: `CDU Jobs`,
      link: `https://www.academicjobs.com/charles-darwin-university-cdu-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/academic%20jobs%20Charles%20Darwin%20University%20%20logo.jpeg`,
    },
    {
      name: `CSU Jobs`,
      link: `https://www.academicjobs.com/charles-sturt-university-csu-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20CSU.png`,
    },
    {
      name: `CQU Jobs`,
      link: `https://www.academicjobs.com/cquniversity-cqu-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20CQ%20Uni.png`,
    },
    {
      name: `Curtin Jobs`,
      link: `https://www.academicjobs.com/curtin-university-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/academic%20jobs%20Curtin%20University%20logo.png`,
    },
    {
      name: `Deakin Jobs`,
      link: `https://www.academicjobs.com/deakin-university-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/academic%20jobs%20Deakin%20University%20logo.svg`,
    },
    {
      name: `ECU Jobs`,
      link: `https://www.academicjobs.com/edith-cowan-university-ecu-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/academic%20jobs%20ecu%20logo.png`,
    },
    {
      name: `Federation Jobs`,
      link: `https://www.academicjobs.com/federation-university-australia-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20Federation%20university%20logo.jpeg`,
    },
    {
      name: `Flinders Jobs`,
      link: `https://www.academicjobs.com/flinders-university-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/academic%20jobs%20Flinders%20University%20logo.png`,
    },
    {
      name: `Griffith University Jobs`,
      link: `https://www.academicjobs.com/griffith-university-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/AcademicJobs_Griffith_University.webp`,
    },
    {
      name: `JCU Jobs`,
      link: `https://www.academicjobs.com/james-cook-university-jcu-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20JCU.jpeg`,
    },
    {
      name: `La Trobe University Jobs`,
      link: `https://www.academicjobs.com/la-trobe-university-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20La%20trobe%20.png`,
    },
    {
      name: `Macquarie University Jobs`,
      link: `https://www.academicjobs.com/macquarie-university-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20macquarie-university.jpeg`,
    },
    {
      name: `Monash University Jobs`,
      link: `https://www.academicjobs.com/monash-university-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20%20Monash.png`,
    },
    {
      name: `Murdoch Jobs`,
      link: `https://www.academicjobs.com/murdoch-university-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/academic%20jobs%20murdoch%20university%20logo.png`,
    },
    {
      name: `QUT Jobs`,
      link: `https://www.academicjobs.com/queensland-university-of-technology-qut-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/academicjob%20QUT.png`,
    },
    {
      name: `RMIT Jobs`,
      link: `https://www.academicjobs.com/rmit-university-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/academic%20jobs%20RMIT.svg`,
    },
    {
      name: `SCU Jobs`,
      link: `https://www.academicjobs.com/southern-cross-university-scu-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20Southern%20Cross%20University.png`,
    },
    {
      name: `Swinburne Jobs`,
      link: `https://www.academicjobs.com/swinburne-university-of-technology-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/academic%20jobs%20Swinburne%20University%20of%20Technology%20logo.png`,
    },
    {
      name: `The University of Adelaide Jobs`,
      link: `https://www.academicjobs.com/the-university-of-adelaide-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/academic%20jobs%20The%20University%20of%20Adelaide%20logo.gif`,
    },
    {
      name: `UniMelb Jobs`,
      link: `https://www.academicjobs.com/the-university-of-melbourne-unimelb-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20University%20of%20Melbourne.jpeg`,
    },
    {
      name: `The University of Newcastle Jobs`,
      link: `https://www.academicjobs.com/the-university-of-newcastle-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20University%20of%20newcastle.png`,
    },
    {
      name: `UND Jobs`,
      link: `https://www.academicjobs.com/the-university-of-notre-dame-australia-und-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/academic%20jobs%20university%20of%20notre%20dame%20logo.png`,
    },
    {
      name: `UQ Jobs`,
      link: `https://www.academicjobs.com/the-university-of-queensland-uq-jobs`,
      logo_src: `https://www.academicjobs.com/_next/image?url=https%3A%2F%2Facademicjobs.s3.amazonaws.com%2Fimg%2Funiversity-logo%2FAcademic%20Jobs%20University%20of%20Queensland.png&w=384&q=75`,
    },
    {
      name: `USYD Jobs`,
      link: `https://www.academicjobs.com/the-university-of-sydney-usyd-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/USYD_academic_jobs.jpg`,
    },
    {
      name: `UTAS Jobs`,
      link: `https://www.academicjobs.com/the-university-of-tasmania-utas-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs_Utas.jpeg`,
    },
    {
      name: `UWA Jobs`,
      link: `https://www.academicjobs.com/the-university-of-western-australia-uwa-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/academic%20jobs%20uwa%20logo.jpg`,
    },
    {
      name: `Torrens University Australia`,
      link: `https://www.academicjobs.com/torrens-university-australia-jobs`,
      logo_src: `https://www.academicjobs.com/_next/image?url=https%3A%2F%2Facademicjobs.s3.amazonaws.com%2Fimg%2Funiversity-logo%2Facademic%20jobs%20Torrens%20University%20Australia%20Logo.png&w=384&q=75`,
    },
    {
      name: `UC Jobs`,
      link: `https://www.academicjobs.com/university-of-canberra-uc-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20University%20of%20canberra.png`,
    },
    {
      name: `UNE Jobs`,
      link: `https://www.academicjobs.com/university-of-new-england-une-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/461011bcaf5b5e723e897c33f049f169_XL.jpg`,
    },
    {
      name: `UNSW Jobs`,
      link: `https://www.academicjobs.com/university-of-new-south-wales-unsw-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20UNSW.png`,
    },
    {
      name: `UniSA Jobs`,
      link: `https://www.academicjobs.com/university-of-south-australia-unisa-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/academic%20jobs%20uniSA%20logo.jpg`,
    },
    {
      name: `UniSQ Jobs`,
      link: `https://www.academicjobs.com/university-of-southern-queensland-unisq-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20university%20of%20southern%20queensland.png`,
    },
    {
      name: `UniSC Jobs`,
      link: `https://www.academicjobs.com/university-of-the-sunshine-coast-unisc-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20Uni%20Sunshine%20Coast.jpeg`,
    },
    {
      name: `UTS Jobs`,
      link: `https://www.academicjobs.com/university-of-technology-sydney-uts-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/uts-logo-150h.png`,
    },
    {
      name: `UOW Jobs`,
      link: `https://www.academicjobs.com/university-of-wollongong-uow-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/logo-shorthand-horizontal.png`,
    },
    {
      name: `VU Jobs`,
      link: `https://www.academicjobs.com/victoria-university-vu-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Academic%20Jobs%20Victoria%20University.png`,
    },
    {
      name: `WSU Jobs`,
      link: `https://www.academicjobs.com/western-sydney-university-wsu-jobs`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/image_2023-10-13_151108330.png`,
    },
  ];
  const items = [
    {
      src: "/home/academic-faculty-positions.png",
      alt: "Academic Faculty Positions",
      label: "Faculty",
      link: `https://www.academicjobs.com/australia/faculty`,
    },
    {
      src: "/home/academic-executive-positions.png",
      alt: "Academic Executive Positions",
      label: "Executive",
      link: `https://www.academicjobs.com/australia/executive`,
    },
    {
      src: "/home/academic-human-resource-positions.png",
      alt: "Academic Human Resource Positions",
      label: "HR Jobs",
      link: `https://www.academicjobs.com/australia/hr-jobs`,
    },
    {
      src: "/home/academic-phd-positions.png",
      alt: "Academic PhD Positions",
      label: "PhD Jobs",
      link: `https://www.academicjobs.com/australia/phd`,
    },
    {
      src: "/home/academic-staff-positions.png",
      alt: "Academic Staff Positions",
      label: "Staff/Admin",
      link: `https://www.academicjobs.com/australia/admin`,
    },
  ];
  const keyWordRef = useRef(null);
  const cfRef = useRef(null);

  const onEditorStateSelect = (
    specialty_name, subcategory_name, subcategory_column, master_category_name
  ) => {
  
    cfRef.current = specialty_name
    ? { category: 'thirdcategory', filter: specialty_name }
    : { category: subcategory_column, filter: subcategory_name };
    }
  const onInputChange = (inputText) => {
    keyWordRef.current = inputText;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let q3 = "";
    if (cfRef.current) {
      q3 = `&filter0=[{"category":"${cfRef.current.category}","filter":"${cfRef.current.filter}"}]`;
    } else {
      if (keyWordRef?.current?.trim()) {
        q3 = `&q=${keyWordRef?.current?.trim()}`;
      }
    }
    const url = `https://www.academicjobs.com/university-jobs?r=Australia${q3}`;
    //alert(url); // Just for debugging purposes
    window.open(url, "_blank");
  };

  const size = 180;

  return (
    <div className="aheia_theme flex flex-col gap-4 justify-center items-center min-h-screen py-10 px-4 mb-16">
      {/* <div className="container hidden">
        <img
          src="/aheia/sample-header.jpg"
          alt="AHEIA Logo"
          className="ml-0 mb-10"
        />
        <img
          src="/aheia/sample-header-logos.jpg"
          alt="AHEIA Logo"
          className="mx-auto"
        />
        <h1 className="text-5xl text-center my-4 text-aheia-orange">
          Australia's University Jobs Website
        </h1>
        <h2 className="plain text-xl text-center mx-auto max-w-[800px]">
          The AHEIA & AcademicJobs strategic partnership that lists all
          Australian university jobs on one centralised platform, affordably and
          efficiently.
        </h2>
      </div> */}

      <ul className="hero-icons hide flex gap-2 md:gap-5 mx-auto text-center items-center justify-between md:justify-center p-4 z-20">
        {items.map((item, index) => (
          <li key={index}>
            <Link
              target="_blank"
              rel="noopener"
              className="grayscale hover:grayscale-0"
              to={item.link}
            >
              <img src={item.src} width={size} height={size} alt={item.alt} />
            </Link>
            <Link className="mb-4" href={item.link}>
              <p>{item.label}</p>
            </Link>
          </li>
        ))}
      </ul>

      <form
        className="flex flex-col gap-1 items-center w-[50%]"
        onSubmit={handleFormSubmit}
      >
        <div className="bg-white relative flex flex-col md:flex-row md:gap-2 mx-18 w-full  md:border rounded-t-lg md:rounded-lg p-2 md:shadow-md">
          <div className="relative z-50 flex-grow flex items-center">
            {/* Ensure the container is flex and items-center */}
            <JobKeywordSearchBlock
              forceClass=""
              onSelect={onEditorStateSelect}
              onInputChange={onInputChange}
              className="w-full"
              label={
                "Enter role, select it from the drop-down list & click Search"
              }
            />
          </div>
          <button
            className=" ml-auto py-2 px-4 bg-[#f4a10c] w-full md:w-auto text-white rounded-md rounded-b-lg hover:bg-orange-600 animate-pulse font-bold shadow-md"
            type="submit"
          >
            Search
          </button>
        </div>
      </form>

      <ul className="position-links flex flex-wrap gap-4 items-center justify-center text-gray-400 w-full md:w-auto mt-6">
        <li>
          <Link
            target="_blank"
            rel="noopener"
            to={`https://www.academicjobs.com/australia/lecturer?r=Australia&filter0=[{"category":"PositionType","filter":"Lecturer/Instructor"}]`}
          >
            Lecturer
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="noopener"
            to={`https://www.academicjobs.com/australia/research`}
          >
            Research
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="noopener"
            to={`https://www.academicjobs.com/australia/professor`}
          >
            Professor
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="noopener"
            to={`https://www.academicjobs.com/australia/hr-jobs`}
          >
            HR
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="noopener"
            to={`https://www.academicjobs.com/australia/postdoc`}
          >
            Postdoc
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="noopener"
            to={`https://www.academicjobs.com/australia/phd`}
          >
            PhD
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="noopener"
            to={`https://www.academicjobs.com/australia/indigenous?r=Australia&q=indigenous`}
            className="flex gap-2 items-center"
          >
            Indigenous
            <figure>
              <img
                src={"/flags/australian-aboriginal-flag.svg"}
                alt="Indigenous Australian Aboriginal Flag"
                height={600}
                className="w-[36px]"
              />
            </figure>
          </Link>
        </li>
      </ul>
      <ul className="city-links flex flex-wrap justify-center items-center md:gap-6 gap-3 px-7 pb-4 mx-auto">
        <li>
          <Link
            target="_blank"
            rel="noopener"
            className="text-center"
            to="https://www.academicjobs.com/melbourne"
          >
            Melbourne
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="noopener"
            className="text-center"
            to="https://www.academicjobs.com/sydney"
          >
            Sydney
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="noopener"
            className="text-center"
            to="https://www.academicjobs.com/brisbane"
          >
            Brisbane
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="noopener"
            className="text-center"
            to="https://www.academicjobs.com/perth"
          >
            Perth
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="noopener"
            className="text-center"
            to="https://www.academicjobs.com/adelaide"
          >
            Adelaide
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="noopener"
            className="text-center"
            to="https://www.academicjobs.com/canberra"
          >
            Canberra
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="noopener"
            className="text-center"
            to="https://www.academicjobs.com/gold-coast"
          >
            Gold Coast
          </Link>
        </li>
        <li>
          <Link
            className="text-center"
            rel="noopener"
            to={`https://www.academicjobs.com/tasmania?r=Australia&filter0=[{"category":"State","filter":"Tasmania"}]`}
          >
            Tasmania
          </Link>
        </li>
      </ul>

      <div className="w-full max-w-[1400px]">
        <h2 className="pt-16 pb-5 text-2xl md:text-3xl ">
          Search University Jobs
        </h2>
        <p className="mb-6 font-bold">
          Click on a university logo to see jobs currently advertised…
        </p>
        <ul className="w-full remove_bullet grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
          {cityAndUniversityLinks.map((uni, index) => (
            <li key={index} className="m-0 p-0 rounded-3xl cursor-pointer">
              <Link target="_blank" rel="noopener" to={uni.link}>
                <div class="flip-card rounded-3xl" tabIndex="0">
                  <div class="flip-card-inner rounded-3xl">
                    <div class="flip-card-front rounded-3xl p-4">
                      <img
                        src={uni.logo_src}
                        alt={"AcademicJobs Logo"}
                        className="w-[180px] h-auto "
                      />
                    </div>
                    <div class="flip-card-back rounded-3xl p-4">
                      <h3 className="uni_name m-0 p-0 text-2xl font-bold">
                        {uni.name}
                      </h3>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <h3p className="mx-auto max-w-[800px] mt-10 text-aheia-orange text-2xl">
        <strong>Benefits to Universities:</strong>
      </h3p>
      <ul className="list-disc list-outside ml-4 mb-16">
        <li>
          Increasing job advertising reach, reducing advertising costs and
          Talent Acquisition admin time
        </li>
        <li>
          The easiest and most efficient way for job seekers to find and compare
          all university jobs in Australia
        </li>
        <li>
          Continuously developed through collaboration with all Australian
          universities
        </li>
        <li>Start or expand your academic career today!</li>
      </ul>
    </div>
  );
}
