import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { setJob, setId, setEmployer } from '../store/postsSlice'

const LowJobCountEmployers = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="bg-amber-500 p-6 rounded-lg shadow-black shadow-sm">
      {data.length > 0 && data?.map(({ country, employers }, i) => (
        (country === 'United States' || country === 'United Kingdom') && (
          <div key={i} className="mb-8">
            <h3 className="text-gray font-bold text-lg mb-2 underline">{country}</h3>
            <div className="grid grid-cols-5 gap-4">
              <div className="col-span-2 font-bold">Company</div>
              <div className="col-span-1 font-bold">No. listed on AJ (leads)</div>
              <div className="col-span-1 font-bold">No. of jobs on company website</div>
              <div className="col-span-1 font-bold">Jobs to be loaded</div>
            </div>
            <div className="overflow-y-auto max-h-60 custom-scrollbar bg-white border rounded-xl mb-2 p-2">
              {employers.map(({ id, company_name, approxJobQtyWebsite, logo, job_count, salesLeads_count }, j) => (
                <div key={j} className="grid grid-cols-5 gap-4">
                  <div className="col-span-2">
                    <span href={`/employers-center/${id}/`} className="text-gray-600 hover:underline hover:text-blue-500 cursor-pointer"
                      onClick={() => {
                        dispatch(setEmployer({ employer_id: id, company_name: company_name, logo: logo }))
                        navigate(`/employers-center/${id}/`)
                      }}>
                      {company_name}
                    </span>
                  </div>
                  <div className="col-span-1">
                    {job_count} (<span style={{ color: 'red' }}>{salesLeads_count}</span>)
                  </div>                
                  <div className="col-span-1">{approxJobQtyWebsite ? approxJobQtyWebsite : "N/A"}</div>
                  <div className="col-span-1">{5 - job_count}</div>
                </div>
              ))}
            </div>
          </div>
        )
      ))}
    </div>
  );
};

export default LowJobCountEmployers;
