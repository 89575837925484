import React, { useState } from "react";
import { useGetAllUsersQuery } from './auth/authApiSlice';
import UserEdit from "./UserEdit";

const UsersTask = () => {
    const { data, error, isLoading } = useGetAllUsersQuery();
    const [userId, setUserId] = useState(83);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading users. Please try again later.</div>;
    }

    const employees = data?.employees || [];

    // Split users by orgnization_name
    const manilaUsers = employees.filter(user => user.orgnization_name === 'Manila');
    const academicJobsUsers = employees.filter(user => user.orgnization_name === 'AcademicJobs');

    const renderUserCard = ({ id, firstName, lastName, portrait }) => (
        <div 
            key={id} 
            className="card w-[100px] h-[130px] bg-base-100 shadow-xl flex flex-col items-center justify-center"
            onClick={() => setUserId(id)}
        >
            {portrait ? (
                <img
                    src={`https://academicjobs.s3.amazonaws.com/img/users/${portrait}`}
                    alt={firstName}
                    className="w-[100px] h-[100px] object-contain rounded-md bg-white"
                />
            ) : (
                <div className="w-[100px] h-[100px] flex items-center justify-center bg-gray-200 text-xl font-bold text-gray-700 rounded-md">
                    {firstName.toUpperCase()}
                </div>
            )}
            <div className="text-center mt-2 text-sm">
                <p>{firstName}</p>
            </div>
        </div>
    );

    return (
        <div className="flex flex-col gap-4 w-full px-4">
            <p className="text-3xl font-bold text-black shadow-xl px-2 pb-4 mt-4 mb-6">Team Notes</p>

            {/* manila Users Section */}
            <div>
                <div className="justify-center flex flex-wrap gap-4 pb-4">
                <h2 className="text-2xl font-semibold mb-4">manila</h2>
                    {manilaUsers.length > 0 ? manilaUsers.map(renderUserCard) : <div>No users found.</div>}
                </div>
            </div>

            {/* AcademicJobs Users Section */}
            <div>
                <div className="justify-center flex flex-wrap gap-4 pb-4">
                <h2 className="text-2xl font-semibold mb-4">Melbourne</h2>

                    {academicJobsUsers.length > 0 ? academicJobsUsers.map(renderUserCard) : <div>No users found.</div>}
                </div>
            </div>

            <UserEdit id={userId} />
        </div>
    );
};

export default UsersTask;
