import React from 'react';
import { useGetMainSecondContactsQuery } from "../store/apiSlice"

const ContactsTable = () => {
    const { data } = useGetMainSecondContactsQuery();
    let content
    const groupedData = {};
    if (data?.length > 0) {
        data.forEach(company => {
            if (company.main_contact) {
                if (!groupedData[company.country]) {
                    groupedData[company.country] = [];
                }
                groupedData[company.country].push(company);
            }
        });
        content = <div>
            {Object.keys(groupedData).map(country => (
                <div key={country}>
                    <h2 className="text-gray-600 font-bold p-3">{country}</h2>
                    <div className="custom-scrollbar h-[500px] overflow-y-auto">
                        <table className="w-full table-auto border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="sticky top-0 z-10 border border-gray-300 py-2 px-4 bg-gray-50">Company Name</th>
                                <th className="sticky top-0 z-10 border border-gray-300 py-2 px-4 bg-gray-50">First Name</th>
                                <th className="sticky top-0 z-10 border border-gray-300 py-2 px-4 bg-gray-50">Last Name</th>
                                <th className="sticky top-0 z-10 border border-gray-300 py-2 px-4 bg-gray-50">Title</th>
                                <th className="sticky top-0 z-10 border border-gray-300 py-2 px-4 bg-gray-50">Email</th>
                                <th className="sticky top-0 z-10 border border-gray-300 py-2 px-4 bg-gray-50">Phone</th>
                            </tr>
                            </thead>
                            <tbody>
                                {groupedData[country].map((company, index) => (
                                    <React.Fragment key={index}>
                                        <tr className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                                            <td className="border border-gray-300 py-2 px-4" rowSpan="2">
                                                {company.company_name}
                                            </td>
                                            <td className="border border-gray-300 py-2 px-4">
                                                {company.firstname1}
                                            </td>
                                            <td className="border border-gray-300 py-2 px-4">
                                                {company.lastname1}
                                            </td>
                                            <td className="border border-gray-300 py-2 px-4">
                                                {company.jobtitle1}
                                            </td>
                                            <td className="border border-gray-300 py-2 px-4">
                                                {company.email1}
                                            </td>
                                            <td className="border border-gray-300 py-2 px-4">
                                                {company.phone1}
                                            </td>
                                        </tr>
                                        <tr className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                                            <td className="border border-gray-300 py-2 px-4">
                                                {company.firstname2}
                                            </td>
                                            <td className="border border-gray-300 py-2 px-4">
                                                {company.lastname2}
                                            </td>
                                            <td className="border border-gray-300 py-2 px-4">
                                                {company.jobtitle2}
                                            </td>
                                            <td className="border border-gray-300 py-2 px-4">
                                                {company.email2}
                                            </td>
                                            <td className="border border-gray-300 py-2 px-4">
                                                {company.phone2}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
        </div>
    }
    return <div>{content}</div>
};

export default ContactsTable;
