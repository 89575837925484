import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

const AcademicConnections = ({ id, academic_connections_count, academic_connections }) => {
  const [showDetails, setShowDetails] = useState({});

  const toggleDetails = (id) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div className="">
    {academic_connections_count > 0 && 
      <div className="">
        Highly qualified referrals: {academic_connections_count}

        <button
          onClick={() => toggleDetails(id)}
          className="ml-2 text-blue-500 hover:underline"
        >
          {showDetails[id] ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </button>
      </div>}
      {showDetails[id] && (
        <div
          className="mt-4 bg-gray-100 border-2 rounded-lg z-50 absolute top-full left-0 w-full max-h-60 overflow-y-auto custom-scrollbar"
          style={{ zIndex: 1000 }}
        >
          {academic_connections && academic_connections.length > 0 ? (
            <table className="table-auto w-full text-sm text-left text-gray-600">
              <thead className="bg-white sticky top-0">
                <tr>
                  <th className="py-2 px-4">First Name</th>
                  <th className="py-2 px-4">Organisation email (domain)</th>
                  <th className="py-2 px-4">Referred by</th>
                </tr>
              </thead>
              <tbody>
                {academic_connections.map((connection, idx) => (
                  <tr key={idx}>
                    <td className="py-2 px-4">{connection.firstname}</td>
                    <td className="py-2 px-4" style={{ color: connection.email.includes('@') ? '#3498db' : '#808080' }}>{connection.email}</td>
                    <td className="py-2 px-4">{connection.firstNameInviter}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-sm text-gray-600">
              No connections available.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AcademicConnections;
