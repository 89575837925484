import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-quill/dist/quill.snow.css";
import JobList1 from "./JobList1";
import { useNavigate } from "react-router-dom";
import { useGetJobs2Query, useGetQty1Query } from "../store/apiSlice";
import { setJob } from "../store/postsSlice";
import { BsHandbag } from "react-icons/bs";

const SearchResults2 = ({ q }) => {
  const [viewCurrent, setViewCurrent] = useState(true);
  const [q1, setQ1] = useState(q);  // Manage query state
  const [page, setPage] = useState(0); // Manage pagination
  const navigate = useNavigate();
  const job = useSelector((state) => state.posts.job);
  const componentRef = useRef(null);
  
  const dispatch = useDispatch();

  // Use layout effect to scroll to top when the job changes
  useLayoutEffect(() => {
    if (componentRef.current) {
      componentRef.current.scrollTop = 0;
    }
  }, [job]);

  // Query jobs data, including loading state
  const { data, isLoading, isSuccess } = useGetJobs2Query({
    ...q1,
    page,
  });

  // Query for job quantity
  const { data: qty, isLoading: isLoadingQty } = useGetQty1Query(q);

  // Update the job in the store when data is available
  useEffect(() => {
    if (data) dispatch(setJob(data[0]));
  }, [data, dispatch]);

  // Handle pagination
  function nextPage() {
    setPage((prev) => prev + 1);
  }

  let content;
  
  // If loading, display loading cards
  if (isLoading) {
    content = (
      <div className="flex w-full h-screen justify-between">
        <div
          role="status"
          className="h-screen p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
        >
          {/* Loading skeletons */}
          {[...Array(5)].map((_, i) => (
            <div key={i} className="flex items-center justify-between pt-4">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
          ))}
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  } else if (isSuccess) {
    // If data is loaded, render the content
    content = (
      <div className="flex flex-col md:flex md:justify-start rounded-3xl">
        <div className="mt-6 justify-start items-start font-bold gap-2">
          <div className="flex gap-2 mb-2">
            <BsHandbag />
            <span>Jobs - </span>
            {qty?.currentQty && (
              <span
                className={`cursor-pointer ${viewCurrent && "underline"}`}
                onClick={() => {
                  setViewCurrent(true);
                  setQ1((p) => ({ ...p, expired: "0" }));
                }}
              >
                Current {qty?.currentQty} (<span style={{ color: 'red' }}>{qty?.currentSalesLeadsQty}</span>)
              </span>
            )}
            <span>|</span>
            {qty?.expiredQty && (
              <span
                className={`cursor-pointer ${viewCurrent || "underline"}`}
                onClick={() => {
                  setViewCurrent(false);
                  setQ1((p) => ({ ...p, expired: "1" }));
                }}
              >
                Expired {qty?.expiredQty} (<span style={{ color: 'red' }}>{qty?.expiredSalesLeadsQty}</span>)
              </span>
            )}
          </div>
          {/* Sort buttons */}
          <div className="flex gap-4">
            <button
              className="btn btn-xs"
              onClick={() => {
                setQ1((p) => ({ ...p, sort: "title" }));
              }}
            >
              Sort By Title
            </button>
            <button
              className="btn btn-xs"
              onClick={() => {
                setQ1((p) => ({ ...p, sort: "date" }));
              }}
            >
              Sort By Date
            </button>
            <button
              className="btn btn-xs"
              onClick={() => {
                setQ1((p) => ({ ...p, sort: "headline" }));
              }}
            >
              Sort By Headline Only
            </button>
            <button
              className="btn btn-xs"
              onClick={() => {
                setQ1((p) => ({ ...p, sort: "fullJob" }));
              }}
            >
              Sort By Full Job
            </button>
            <button
              className="btn btn-xs bg-amber-500"
              onClick={() => {
                setQ1((p) => ({ ...p, sort: "academicConnections" }));
              }}
            >
              Academic Connections
            </button>
          </div>
        </div>

        <div className="overflow-y-auto mt-4">
          <JobList1 data={data} nextPage={nextPage} />
        </div>
      </div>
    );
  }

  return (
    <div className="relative overflow-y w-full">
      {content}
    </div>
  );
};

export default SearchResults2;
