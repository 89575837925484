import React, { useState, useEffect, useRef } from "react";
import { useGetJobKeywordSuggestionsQuery } from "../store/apiSlice";

const JobKeywordSearchBlock = ({
  label,
  onSelect,
  initialSelectedKeywords = []
}) => {
  const [query, setQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);

  const [selectedKeywords, setSelectedKeywords] = useState(initialSelectedKeywords);
  const inputRef = useRef();
  const { data: suggestions = [] } = useGetJobKeywordSuggestionsQuery({ query });

  useEffect(() => {
    // Set the initial keywords when the component mounts or updates
    setSelectedKeywords(initialSelectedKeywords);
  }, [initialSelectedKeywords]);

  const handleInputClick = () => {
    setShowSuggestions(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    setShowSuggestions(true);
    setQuery(e.target.value);
  };

  const handleSelect = (keyword) => {
    if (!selectedKeywords.includes(keyword)) {
      const updatedKeywords = [...selectedKeywords, keyword];
      setSelectedKeywords(updatedKeywords);
      onSelect(updatedKeywords);
    }
    setQuery('');
    setShowSuggestions(false);
  };

  const handleRemoveKeyword = (keyword) => {
    const updatedKeywords = selectedKeywords.filter(item => item !== keyword);
    setSelectedKeywords(updatedKeywords);
    onSelect(updatedKeywords);
  };

  return (
    <div className="w-full bg-white" ref={inputRef}>
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        onClick={handleInputClick}
        className={`w-full input input-md input-bordered focus:outline-none focus:border-black `}
        autoComplete="off"
        placeholder={label}
      />
      {showSuggestions && (
        <ul className="mt-2 list-none z-10">
          {suggestions.map(({ category2 }, index) => (
            <li
              key={index}
              className="bg-white w-full py-1 px-1 border-b border-gray-300 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSelect(category2)}
            >
              <div className="w-[100%]">
                <span className="text-sm font-bold">{category2}</span>
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className="mt-2 flex flex-wrap gap-2">
        {selectedKeywords.map((keyword, index) => (
          <div key={index} className="bg-blue-100 px-3 py-1 rounded-sm flex items-center">
            <span>{keyword}</span>
            <button
              type="button"
              className="ml-2 text-red-500"
              onClick={() => handleRemoveKeyword(keyword)}
            >
              ×
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobKeywordSearchBlock;
