import React, { useState, useEffect, useRef, useMemo } from "react";
import {
    useGetJobQtyAgentQuery,
} from "../store/apiSlice";
import { useSelector, useDispatch } from "react-redux";
const JobLoadingActivity = () => {
    const { data, isLoading, isSuccess } = useGetJobQtyAgentQuery();
    return (
        <div className="max-w-4xl  mx-auto table-auto  flex flex-col gap-4 justify-center items-center">
            
            <div className="  bg-slate-200 p-10 rounded-xl mb-8">
           
           <div>
             <h1 className="lg:text-5xl font-bold  text-center text-amber-500">
             Academic Talent Pool<br/> 
             </h1>
             <p className="text-blue-800 text-3xl font-bold text-center pt-2">"GET HAPPY, go global, find meaning."</p>
           
           </div>
         </div>

         <div className="  bg-slate-200 p-10 rounded-xl mb-8 px-40">
           
           <div>
             <h1 className="lg:text-2xl font-bold text-amber-500">
             PERMA<br/> 
             </h1>
             <p className="text-blue-800 text-xl   pt-2 underline pb-4">Happiness</p>
           
                                    <ul className="list-none  flex flex-col gap-1">
                                        <li className="relative before:content-['✔'] before:block before:text-green-500 before:absolute before:left-0">
                                            <span className="ml-12">Pleasure (Positive Emotion) - Hope & Gratitude</span>
                                        </li>
                                        <li className="relative before:content-['✔'] before:block before:text-green-500 before:absolute before:left-0">
                                            <span className="ml-12">Engagement</span>
                                        </li>
                                        <li className="relative before:content-['✔'] before:block before:text-green-500 before:absolute before:left-0">
                                            <span className="ml-12">Relationships - Connect + Collaborate</span>
                                        </li>
                                        <li className="relative before:content-['✔'] before:block before:text-green-500 before:absolute before:left-0">
                                            <span className="ml-12">Meaning</span>
                                        </li>
                                        <li className="relative before:content-['✔'] before:block before:text-green-500 before:absolute before:left-0">
                                            <span className="ml-12">Accomplishments/Achievements</span>
                                        </li>
                                    </ul>

                                    <p className="text-blue-800 text-xl   pt-2  pb-4">Optimism, Physical Activity, Nutrition, Sleep</p>

           </div>
         </div>

      
        </div>
    )
};
export default JobLoadingActivity;
