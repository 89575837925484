import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setJob, setId, setEmployer } from '../store/postsSlice';

const TopUniversityPadding = ({ data }) => {
  const sortedRegions = Object.keys(data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let totalJobCount = 0;
  let totalJobsToBeLoaded = 0;

  return (
    <div className="bg-amber-500 p-6 rounded-lg shadow-black shadow-sm">
                <div className="grid grid-cols-5 gap-4">
            <div className="col-span-2 font-bold">Copmany</div>
            <div className="col-span-1 font-bold">No. listed on AJ</div>
            <div className="col-span-1 font-bold">No. of jobs on company website</div>
            <div className="col-span-1 font-bold">Jobs to be loaded</div>
          </div>
      {Object.entries(data).map(([region, list]) => (
        <div key={region} className="mb-8">
          <h2 className="text-gray-600 font-bold text-lg mb-4">{region}</h2>
          <div className="overflow-y-auto max-h-60 custom-scrollbar bg-white border rounded-xl mb-2 p-2">
            <ul className="list-disc list-inside w-full">
              {list
                .map(({ id, logo, company_name, job_count, approxJobQtyWebsite }) => (
                  <li key={id} className="grid grid-cols-5 gap-4 font-light">
                    <div className="col-span-2">
                      <a href={`/employers-center/${id}/`} className="text-gray-600 hover:underline hover:text-blue-500"
                        onClick={() => {
                          dispatch(setEmployer({ employer_id: id, company_name: company_name, logo: logo }));
                          navigate(`/employers-center/${id}/`);
                        }}>
                        {company_name}
                      </a>
                    </div>
                    <div className="col-span-1">{job_count}</div>
                    <div className="col-span-1">{approxJobQtyWebsite > 0 ? approxJobQtyWebsite : "N/A"}</div>
                    <div className="col-span-1">{approxJobQtyWebsite > job_count ? approxJobQtyWebsite-job_count : (30-job_count)}</div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};
export default TopUniversityPadding;
