import { Outlet } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'

const Layout = () => {
  const darkMode = useSelector((state) => state.posts.darkMode)
  return (
    <div className={`w-full min-h-screen ${darkMode}`}>
      <div className='w-full min-h-screen'>
        <Outlet />
      </div>
    </div>
  )
}

export default Layout
