import { useNavigate, useLocation } from "react-router-dom";
import React from "react";
import { VisaIcon } from "../components/icons/VisaIcon";
import { MastercardIcon } from "../components/icons/MastercardIcon";

const invoiceData = {
  date: "04 April 2024",
  taxCode: "GST (10%)",
  currencySymbol: "$",
  currency: "USD",
  totalPrice: 100,
  businessName: "AcademicJobs",
  businessABN: "66 977 584 208",
  businessAddress: "Suite 7, 72-74 Church Street",
  businessCity: "Brighton",
  businessState: "Victoria",
  businessPostcode: "3186",
  businessCountry: "Australia",

  productItem: "Product/Service Name",
  email: "email@example.com",
  phone: "(00) 123 456 789",

  customerName: "JobElephant",
  customerFirstName: "Tony",
  customerLastName: "Ngo",
  customerABN: "",
  customerAddress: "5443 Fremontia Lane",
  customerCity: "San Diego",
  customerState: "CA",
  customerPostcode: "92115",
  customerCountry: "UNITES STATES OF AMERICA",

  invoiceNumber: "12345",
  invoiceDate: "04 April 2024",
  dueDate: "12 April 2024",
  items: [
    {
      description: "Single Job Post",
      qty: 1,
      unitPrice: 315,
      amount: 315,
    },
    // Add more items as needed
  ],
  subtotal: 100,
  tax: 10,
  total: 110,
};

const InvoiceComponent = () => {
  const {
    date,
    firstName,
    lastName,
    productItem,
    taxCode,
    currencySymbol,
    currency,
    businessName,
    businessABN,
    businessAddress,
    businessCity,
    businessState,
    businessPostcode,
    businessCountry,
    email,
    phone,
    customerName,
    customerFirstName,
    customerLastName,
    customerABN,
    customerAddress,
    customerCity,
    customerState,
    customerPostcode,
    customerCountry,
    invoiceNumber,
    invoiceDate,
    dueDate,
    items,
    subtotal,
    tax,
    total,
  } = invoiceData;

  return (
    <main className="content-grid">
      <h2 className="bg-white w-[210mm] mx-auto mb-16 text-gray-600 font-bold text-3xl">
        Invoice Number {invoiceNumber}
      </h2>
      <section
        title="Invoice"
        className="invoice bg-white p-8 w-[210mm] mx-auto border border-slate-200"
      >
        <div className="flex justify-between items-center mb-6">
          <img
            // src="https://academicjobs.s3.amazonaws.com/img/_misc/academic-jobs-logo.png"
            src="/brands/academic-jobs-logo.svg"
            alt="AcademicJobs Logo"
            className="pl-4 w-[20rem] mb-[1rem] "
          />
          <div className="text-right">
            <p className="text-lg font-semibold">
              {taxCode !== "" ? "Tax" : ""} Invoice Number: {invoiceNumber}
            </p>
            <p className="text-sm">{date}</p>
          </div>
        </div>
        <div className="flex justify-between border-b-2 border-gray-200 pb-6 mb-6">
          <div>
            <p className="font-bold">{customerName}</p>

            {customerABN !== "" ? (
              <p className="text-xs">ABN: {customerABN}</p>
            ) : null}

            {customerFirstName !== "" ? (
              <p className="mt-2">
                Att: {customerFirstName} {customerLastName}
              </p>
            ) : null}

            <p className="mt-2">{customerAddress}</p>
            <p>
              {customerCity} {customerState} {customerPostcode}
            </p>
            <p>{customerCountry}</p>
          </div>
          <div className="text-right">
            <p className="font-bold">{businessName}</p>

            {businessABN !== "" ? (
              <p className="text-xs">ABN: {businessABN}</p>
            ) : null}

            <p className="mt-2">{businessAddress},</p>
            <p>
              {businessCity}, {businessState}, {businessPostcode}
            </p>
            <p>{businessCountry}</p>
          </div>
        </div>
        <table className="invoice-items table my-4">
          <thead>
            <tr>
              <th>Description</th>
              <th>Qty</th>
              <th>Unit Price</th>
              <th>GST</th>
              <th className="text-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>{item.description}</td>
                <td>{item.qty}</td>
                <td>
                  {currencySymbol}
                  {item.unitPrice.toFixed(2)}
                </td>
                <td>
                  {currencySymbol}
                  {(item.amount * 0.1).toFixed(2)}
                </td>
                <td className="text-right">
                  {currencySymbol}
                  {(item.amount * 1.1).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end">
          <table className="w-1/3">
            <tbody>
              <tr>
                <td className="font-semibold">Subtotal</td>
                <td className="text-right">
                  {currencySymbol}
                  {subtotal.toFixed(2)}
                </td>
              </tr>
              {taxCode !== "" ? (
                <tr>
                  <td className="font-semibold">{taxCode}</td>
                  <td className="text-right">
                    {currencySymbol}
                    {tax.toFixed(2)}
                  </td>
                </tr>
              ) : null}
              <tr>
                <td className="font-semibold">Amount Due</td>
                <td className="text-right">
                  {currency}{" "}
                  <span className="font-bold">
                    {currencySymbol}
                    {total.toFixed(2)}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h2 className="text-black font-bold text-[16px]">
          Due Date: 18 Apr 2024
        </h2>
        <p>Payment via bank deposit:</p>
        <p>Westpac</p>
        <p className="mt-4">
          <span className="font-bold text-slate-600">Name: </span>Post my Job
          Pty Ltd t/a AcademicJobs
        </p>
        <p>
          <span className="font-bold text-slate-600">BSB: </span>033095
        </p>
        <p>
          <span className="font-bold text-slate-600">Account: </span>592109
        </p>
        <p className="mt-4">
          Please use your invoice number as the payment reference
        </p>
        <div className="cc-icons mt-8 flex gap-1">
          <a href="/" className="btn btn-accent mr-16">
            Pay Now
          </a>
          <div>
            <VisaIcon />
          </div>
          <div>
            <MastercardIcon />
          </div>
          <div>
            <img
              className="max-h-[50px]"
              src="/icons/american-express-icon.svg"
              alt=""
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default InvoiceComponent;
