import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ element: Component, allowedUserTypes, ...props }) => {
  const userInfo = useSelector((state) => state.auth.userInfo); // Get userInfo from state

  // Check if the user type is allowed
  if (!allowedUserTypes.includes(userInfo?.userType)) {
    return <Navigate to="/not-authorized" replace />;
  }

  // Render the component with the passed props
  return <Component {...props} />;
};

export default ProtectedRoute;
