import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jobElephantContactsData from '../data/jobElephantContacts.json';
import { setUserInfo } from "./auth/authSlice";
import { useGetJobsByPostedByIdMutation } from '../store/apiSlice';
import PartnerJobList from './PartnerJobList';
import { Link } from "react-router-dom";
import Image from '@tiptap/extension-image';
const UserSelectionComponent = () => {
    const dispatch = useDispatch();
    const storedUserInfo = useSelector((state) => state.auth.userInfo);
    const [selectedUser, setSelectedUser] = useState(null);
    const [jobElephantContacts, setJobElephantContacts] = useState([]);

    const [getJobsByPostedById, { data }] = useGetJobsByPostedByIdMutation();

    useEffect(() => {
        setJobElephantContacts(jobElephantContactsData);

        if (storedUserInfo) {
            const user = jobElephantContactsData.find(
                (user) =>
                    user.firstName === storedUserInfo.firstName &&
                    user.lastName === storedUserInfo.lastName &&
                    user.email === storedUserInfo.email
            );
            setSelectedUser(user);
        }
    }, [storedUserInfo]);

    useEffect(() => {
        if (storedUserInfo?.id) {
            getJobsByPostedById({ postedBy: storedUserInfo.id });
        }
    }, [storedUserInfo, getJobsByPostedById]);

    const handleUserSelection = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue === "") {
            setSelectedUser(null);
            dispatch(setUserInfo(null));
        } else {
            const selectedUser = jobElephantContacts.find(
                (user) =>
                    `${user.firstName} ${user.lastName} - ${user.email}` === selectedValue
            );
            setSelectedUser(selectedUser);

            if (selectedUser) {
                dispatch(setUserInfo({
                    firstName: selectedUser.firstName,
                    lastName: selectedUser.lastName,
                    email: selectedUser.email,
                }));
            }
        }
    };
    
    return (
        <div className="p-4 md:flex w-full">
            <div className='w-full md:w-1/2 p-4'>
                <div className="flex flex-col lg:flex-row border shadow-xl rounded-xl p-6 mt-5 bg-white">
                    <div className="lg:w-2/3 w-full mb-4 lg:mb-0">
                        <span className="block text-4xl font-semibold text-[#c1d72e] leading-tight">
                            Welcome to the JobElephant Partner Dashboard!
                        </span>

                        <span className="block text-2xl font-semibold mt-2 leading-tight">
                            Get a Free Featured Job Listing Every Time You Post
                        </span>
                        <span className="block mt-4">
                            <span className="text-2xl font-semibold text-[#265882] leading-tight">
                                Stand Out Above the Rest
                            </span>
                        </span>
                    </div>
                    <div className="lg:w-1/3 w-full flex justify-center lg:justify-end items-center">
                        <img
                            width={200}
                            height={200}
                            src="https://academicjobs.s3.amazonaws.com/img/_misc/jobelephant-puzzle.png"
                            className="lg:max-w-lg bg-white rounded-xl shadow-lg"
                            alt="jobelephant puzzle"
                        />
                    </div>
                </div>
                <div className="w-full p-4 border shadow-lg rounded-xl mt-8">
                    <label className="block font-bold mb-2">Select User:</label>
                    <select
                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                        onChange={handleUserSelection}
                        value={selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName} - ${selectedUser.email}` : ""}
                    >
                        <option value="" disabled>
                            Please select a user...
                        </option>
                        {jobElephantContacts.map((user, index) => (
                            <option
                                key={index}
                                value={`${user.firstName} ${user.lastName} - ${user.email}`}
                            >
                                {user.firstName} {user.lastName} - {user.email}
                            </option>
                        ))}
                    </select>

                    <div className="flex items-center gap-4 mt-4">
                        {selectedUser ? (
                            <>
                                <img
                                    src={`/partners/jobelephant/avatars/${selectedUser.avatar}`}
                                    alt="Avatar"
                                    width={100}
                                    height={100}
                                    className="rounded-full"
                                    onError={(e) => { e.target.src = '/path-to-fallback-image.png'; }}
                                />
                                <p>
                                    Hi <b>{selectedUser.firstName}</b>, welcome to your quick post form.
                                </p>
                            </>
                        ) : (
                            <>
                                <img
                                    src="/generic-avatar.jpg"
                                    alt="Generic Avatar"
                                    width={100}
                                    height={100}
                                    className="rounded-full"
                                />
                                <p className="font-bold">
                                    Select a user before continuing.
                                </p>
                            </>
                        )}
                    </div>
                    <div className="flex justify-center mt-8">
                        <Link
                            className="btn btn-aj text-white font-bold py-2 px-4 rounded"
                            to={`/post-job-partner`}
                        >
                            Post a Job
                        </Link>
                    </div>
                </div>
            </div>

            <div className="w-full md:w-1/2 p-4">
                <div className='overflow-y-auto mt-4'>
                    <PartnerJobList data={data} />
                </div>
            </div>
        </div>

    );
};

export default UserSelectionComponent;
