import React, { useState, useEffect, useRef } from 'react'
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { useSelector, useDispatch } from 'react-redux'
import { setJob, setId, setStatusId } from '../store/postsSlice'
import Job from './Job'
import Modal from '../components/Modal'
import JobDetail from './JobDetail'
import { Link } from "react-router-dom";
const JobList1 = ({ endOfJobs, data, nextPage }) => {
  ///alert(endOfJobs)
  const dispatch = useDispatch()
  let content
  const [isOpen, setIsOpen] = useState(false)
  console.log(data)
  content = data.map((job, index) => {
    const { headlineOnly, email1, activation_date, createdAt, activeYN, employer_id, title, location, expiration_date, how_to_apply, logo, company_name, featured, id } = job
    return (
      <div
        className={`font-bold ${index % 2 === 0 ? '' : ''}`}
        key={index}
      >
    
          <div className="flex items-center pb-2">
            <div className="flex-1">
              <span className="block text-gray-500 text-xl font-bold leading-tight hover:underline cursor-pointer">
                {title}
              </span>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="text-sm text-gray-700 font-light">{company_name}</div>
            <div className="text-sm text-gray-700 font-light">{location}</div>
          </div>
          <div className="flex  justify-between items-center">
        
         
              <Autocomplete
                className="border-2 rounded mt-2 text-pink-400"
                // defaultValue="Start typing job location & allow Google to auto-complete"
                //defaultValue={employer?.location}
                style={{ width: "100%" }}
                //ref={googleAddressRef}
                apiKey="AIzaSyCKEfoOIPz8l_6A8BByD3b3-ncwza8TNiA"
                //{...register("location1")}
                onPlaceSelected={(place) => {
                  console.log("Selected Place:", place);

        
                }}


                // onPlaceSelected={(selected, a, c) => {
                //   console.log(selected,a,c);
                //   setValue("location", selected)
                // }}
                options={{
                  types: ["geocode", "establishment"],
                  //componentRestrictions: { country: "Australia" },
                }}
                //onPlaceSelected={(place) =>
                //formik.setFieldValue("countryAnother", place.formatted_address)
                //}
                //onChange={e => setValue("location", e.target.value)}
              //onChange={e => console.log(e)}
              />
          <button className='btn btn-sm'>Save</button>
          </div>
     
      </div>
    );
  });
  return <div className='overflow-y w-full'>
    {content}
    < button type="button" className="load-more bg-white py-2 px-4 border border-gray-300 rounded-md text-black hover:bg-gray-100"
      onClick={() => { if (!endOfJobs) { nextPage() } }}
    >
      {endOfJobs ? 'End Of Job list' : 'Load more'}
    </button >
  </div>
}
export default JobList1
