import React, { useState, useEffect } from "react";

const KeywordInput = ({ label, initialKeywords = [], onKeywordsChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [keywords, setKeywords] = useState(initialKeywords);

  useEffect(() => {
    // Set the initial keywords when the component mounts or updates
    setKeywords(initialKeywords);
  }, [initialKeywords]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      e.preventDefault();
      if (!keywords.includes(inputValue.trim())) {
        const newKeywords = [...keywords, inputValue.trim()];
        setKeywords(newKeywords);
        onKeywordsChange(newKeywords);
      }
      setInputValue('');
    }
  };

  const handleRemoveKeyword = (keywordToRemove) => {
    const newKeywords = keywords.filter(keyword => keyword !== keywordToRemove);
    setKeywords(newKeywords);
    onKeywordsChange(newKeywords);
  };

  return (
    <div className="w-full">
      <label className="block text-sm font-medium">{label}</label>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className="w-full px-3 py-2 border border-gray-300 rounded-md"
        placeholder="Type a keyword and press enter"
      />
      <div className="mt-2 flex flex-wrap gap-2">
        {keywords.map((keyword, index) => (
          <div key={index} className="bg-blue-100 px-3 py-1 rounded-md flex items-center">
            <span>{keyword}</span>
            <button
              type="button"
              className="ml-2 text-red-500"
              onClick={() => handleRemoveKeyword(keyword)}
            >
              ×
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeywordInput;
