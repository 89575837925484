import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import SearchResults2 from "./SearchResults2";
// import { useGetOrderedProductsQuery } from "../store/apiSlice";
import { useParams, useNavigate, Link, NavLink } from "react-router-dom";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { setJobCredits } from "../store/postsSlice";
import UniSearchBlock1 from "./UniSearchBlock1";
import { useForm } from "react-hook-form";
import EditEmployerProfile1 from "./EditEmployerProfile1";
import SalesLeadsList from "./SalesLeadsList";
import { setJob, setId, setEmployer } from "../store/postsSlice";
import SearchResultsEmployers from "./SearchResultsEmployers";
import AppContextTitleAndQuote from "../components/profile/AppContextTitleAndQuote";
import EmployerStats from "../components/profile/EmployerStats";
function EmployersCenter(props) {
  const { id } = useParams();
  const employer = useSelector((state) => state.posts.employer);
  //alert(employer.employer_id)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employerID, setEmployerID] = useState(employer?.employer_id || 7);
  const region = useSelector((state) => state.posts.region);
  const userInfo = useSelector((state) => state.auth.userInfo);
  // const { data, isLoading, isSuccess } = useGetOrderedProductsQuery({ id: userInfo.id });
  let content, content1;
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    setError,
  } = useForm();
  useEffect(() => {
    register("employer_name", { required: false });
  }, [register]);
  let { expired } = useParams();
  const onEditorStateChange1 = (company_name, id, Region, country) => {

    setEmployerID(id);
    dispatch(setEmployer({ company_name, employer_id: id }));
    //setValue("description", editorState);
  };
  const onSelectUniSearch = (company_name, id, Region, country) => {

    setEmployerID(id);
    dispatch(setEmployer({ company_name, employer_id: id }));
    navigate(`/employers-center/${id}/`);
    //setValue("description", editorState);
  };
  return (
    <div className="w-full flex  flex-col px-6  gap-8 ">
      <p className="text-3xl font-bold text-black shadow-xl px-2 pb-4 mt-4 mb-6">Organistion Profile</p>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="md:w-1/2 w-full">
          <EditEmployerProfile1 id={employer?.employer_id} />
          {/* <SalesLeadsList id={employer?.employer_id} /> */}
        </div>
        <div className=" md:w-1/2 w-full">
          {/* <div className="bg-slate-100 p-4 rounded-lg">
            <EmployerStats organisation={userInfo.orgnization_name} />
          </div> */}
          <SearchResults2
            q={{ employer_id: employer?.employer_id || 0, expired: "0" }}
          />
        </div>
      </div>
    </div>
  );
}
export default EmployersCenter;
