import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import JobListAcademicConnect from "./JobListAcademicConnect";
import { useNavigate } from "react-router-dom";
import {
  useGetJobs3Query,
  useGetQty1Query,
} from "../store/apiSlice";
import CountUp from "react-countup";
import AcademicConnectPromo from "../components/AcademicConnectPromo";

const SearchResults2 = ({ q }) => {
  const [viewCurrent, setViewCurrent] = useState(true);
  const [q1, setQ1] = useState({ ...q, sort: "academicConnectionCountDescending" }); // Default to descending
  const [isAscending, setIsAscending] = useState(true);
  const [initialTotals, setInitialTotals] = useState({
    totalCurrentConnections: 0,
    totalExpiredConnections: 0,
    totalAcademicConnections: 0,
  });
  const hasSetInitialTotals = useRef(false); // Track if totals are already set
  const navigate = useNavigate();
  
  const { data: qty } = useGetQty1Query(q);

  const { data, isSuccess } = useGetJobs3Query(q1);

  const { data: dataExpired, isSuccess: isSuccessExpired } = useGetJobs3Query({
    ...q1,
    page: 0,
    expired: "1",
  });

  const [showReferralModal, setShowReferralModal] = useState(false); // State for modal visibility
  const logoRef = useRef(null); // Reference to the logo

  // Calculate totals on initial load and set them in state
  useEffect(() => {
    if (!hasSetInitialTotals.current && isSuccess && isSuccessExpired) {
      const totalCurrentConnections = data?.reduce(
        (total, job) => total + (job?.academic_connections_count || 0),
        0
      );
      const totalExpiredConnections = dataExpired?.reduce(
        (total, job) => total + (job?.academic_connections_count || 0),
        0
      );
      const totalAcademicConnections =
        totalCurrentConnections + totalExpiredConnections;

      setInitialTotals({
        totalCurrentConnections,
        totalExpiredConnections,
        totalAcademicConnections,
      });
      
      hasSetInitialTotals.current = true; // Mark that totals have been set
    }
  }, [isSuccess, isSuccessExpired, data, dataExpired]);

  const handleSortToggle = () => {
    setIsAscending((prevIsAscending) => {
      const newIsAscending = !prevIsAscending;
      setQ1((prevQ1) => ({
        ...prevQ1,
        sort: newIsAscending
          ? "academicConnectionCountAscending"
          : "academicConnectionCountDescending",
      }));
      return newIsAscending; // Return the new state value
    });
  };

  let content;
  if (isSuccess && data?.length > 0) {
    content = (
      <div className="flex flex-col md:flex md:justify-start rounded-3xl">
        <div className="mt-6 flex justify-start items-start flex-wrap font-bold gap-2">
          <span>Jobs - </span>
          {qty?.currentQty && (
            <span
              className={`cursor-pointer ${viewCurrent && "underline"}`}
              onClick={() => {
                setViewCurrent(true);
                setQ1((prevQ1) => ({ ...prevQ1, expired: "0" }));
              }}
            >{`Current (${qty?.currentQty})`}</span>
          )}
          <span>|</span>
          {qty?.expiredQty && (
            <span
              className={`cursor-pointer ${viewCurrent || "underline"}`}
              onClick={() => {
                setViewCurrent(false);
                setQ1((prevQ1) => ({ ...prevQ1, expired: "1" }));
              }}
            >{`Expired (${qty?.expiredQty})`}</span>
          )}
            <button
              className="btn btn-xs"
              onClick={handleSortToggle}
            >
              {isAscending
                ? "Sort By Referral Count (Ascending)"
                : "Sort By Referral Count (Descending)"}
            </button>
        </div>
        <div className="mt-4 mb-8">
          <JobListAcademicConnect
            data={data}
          />
        </div>
      </div>
    );
  } else {
    content = (
      <div className="flex flex-col gap-4 justify-center">
        <div className="w-full font-bold underline">Your Live Jobs</div>
        <div>No jobs have been posted, please post your first job here:</div>
        <button
          className="text-[#f4a10c] w-[8rem] text-left font-bold shadow-md rounded-full px-4 py-2 border border-[#f4a10c] hover:bg-gradient-to-r from-gray-400 via-amber-500 to-amber-500 hover:text-white"
          onClick={() => {
            navigate("/post-job-employer");
          }}
        >
          Post A Job
        </button>
      </div>
    );
  }

  return (
    <>
      <div className="relative grid grid-cols-1 gap-6 mt-4 sm:grid-cols-4">

        <div
          className="flex flex-col items-center justify-between text-center text-[#969696] md:max-w-[300px]"
          onMouseEnter={() => setShowReferralModal(true)}
          onMouseLeave={() => setShowReferralModal(false)}
          ref={logoRef} // Attach the ref to the logo div
        >
          <img
            src={"https://www.academicjobs.com/academic-connect-logo.svg"}
            width={250}
            height={180}
            alt="AcademicConnect Logo"
          />
        </div>
        <Card
          title="New Connections"
          count={initialTotals.totalCurrentConnections}
        />
        <Card
          title="Previous Connections"
          count={initialTotals.totalExpiredConnections}
        />
        <Card
          title="Total"
          count={initialTotals.totalAcademicConnections}
        />
      </div>
      {showReferralModal && (
        <div
          className="absolute bg-white shadow-lg p-4 rounded-lg z-50"
          style={{
            top: logoRef.current ? `${logoRef.current.getBoundingClientRect().bottom}px` : "0px",
            left: logoRef.current ? `${logoRef.current.getBoundingClientRect().left}px` : "0px",
            width: logoRef.current ? `${logoRef.current.getBoundingClientRect().width}px` : "auto" // Set the width
          }}
          
        >
          <p className="mb-4 text-center"> Send out referrals now using <span className="font-bold text-amber-500">Academic</span><span className="font-bold text-black">Connect</span></p>
          <p className="mb-4 text-center font-bold">The #1 Employee Referral Platform in  Higher Education</p>
          <div className="flex flex-col items-center pb-4">
            <p className="text-sm italic leading-tight">
              Referred applicants are{" "}
              <a
                target="_blank"
                className={`font-bold text-[#735a7a]`}
                rel="noreferrer"
              >
                more qualified
              </a>
            </p>
            <p className="text-sm italic leading-tight">
              Referred candidates are{" "}
              <a
                target="_blank"
                className={`font-bold text-[#a66c98]`}
                rel="noreferrer"
              >
                hired faster
              </a>
            </p>
            <p className="text-sm italic leading-tight mb-0">
              Referred employees{" "}
              <a
                target="_blank"
                className={`font-bold text-[#b56576]`}
                rel="noreferrer"
              >
                stick around longer
              </a>
            </p>
          </div>
        </div>
      )}
      <div className="relative overflow-y w-full">
        {content}
        <AcademicConnectPromo forceClass="m-0" />
      </div>


    </>
  );
};

const Card = ({ title, count }) => {
  return (
    <div className="border-2 rounded-lg text-center shadow-lg">
      <h2 className="text-2xl font-semibold">
        <span className="block">{title}</span>
      </h2>
      <p className="text-4xl font-bold pb-4">
        <CountUp end={count} duration={2.5} />
      </p>
    </div>
  );
};

export default SearchResults2;
