export const academic_positions = {
    "Faculty Positions": [
      "Lecturer/Instructor",
      "Senior Lecturer",
      "Sessional Lecturer",
      "Tutor",
      "Teaching Assistant",
      "Adjunct Faculty",
      "Post-Doc",
      "Tenure",
      "Tenure-track",
    ],
    "Research Positions": [
      "Research",
      "Scientist/Associate",
      "Senior Research Assistant",
      "Research Assistant",
      "Research Fellow",
      "Post Doc Research Fellow",
      "Research Coordinator",
      "Research Manager",
      "Research Technician",
      "Faculty Researcher",
      "PhD Researcher",
    ],
    "Professorial Positions": [
      "Professor",
      "Assistant Professor",
      "Associate Professor",
      "Senior Professor",
      "Adjunct Professor",
      "Visiting Professor",
      "Research Professor",
      "Clinical Professor/Prof of Practice",
      "Emeritus Professor",
      "Visiting Scholar",
      "Visiting Fellow",
    ],
    Others: [
      "Sessional Academic",
      "Clinical Educator",
      "Academic Advisor",
      "Curriculum Developer",
      "Educational Technologist",
      "Learning Specialist",
      "Academic Coach",
      "Lab Manager",
      "Program Director",
      "Academic Coordinator",
      "Exam Supervisor",
      "Librarian",
      "Faculty Development Specialist",
      "Instructional Designer",
    ],
  };
  