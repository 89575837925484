import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setEmployer } from '../store/postsSlice';
import { useNavigate } from 'react-router-dom';

const EmployersList = ({ data }) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); 

    const pastAndTodayData = data.filter(employerData => {
        return employerData.salesNotes.some(note => {
            const actionDate = note.actionDate;
            if (!actionDate) return false;
            const actionDateObject = new Date(actionDate);
            actionDateObject.setHours(0, 0, 0, 0);
            return actionDateObject <= today;
        });
    }).sort((b, a) => new Date(b.salesNotes[0]?.actionDate) - new Date(a.salesNotes[0]?.actionDate));

    const futureOrNoDateData = data.filter(employerData => {
        return employerData.salesNotes.every(note => {
            const actionDate = note.actionDate;
            if (!actionDate) return true;
            const actionDateObject = new Date(actionDate);
            actionDateObject.setHours(0, 0, 0, 0);
            return actionDateObject > today;
        });
    });

    return (
        <div className="p-4">
            <div className="flex flex-col md:flex-row gap-4">
                <div className="bg-slate-200 w-full md:w-1/2 p-6 rounded-xl mb-8 mr-6 ml-4">
                    <h2 className="text-lg font-semibold mb-4">Today's Tasks</h2>
                    {pastAndTodayData.map((employerData) => (
                        <Employer key={employerData.employer.id} employerData={employerData} today={today} />
                    ))}
                </div>
                <div className="bg-slate-200 w-full md:w-1/2 p-6 rounded-xl mb-8 mr-6 ml-4">
                    <h2 className="text-lg font-semibold mb-4">Other Tasks</h2>
                    {futureOrNoDateData.map((employerData) => (
                        <Employer key={employerData.employer.id} employerData={employerData} today={today} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const Employer = ({ employerData, today }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const pastSalesNotes = employerData.salesNotes.filter(note => {
        const actionDate = note.actionDate;
        if (!actionDate) return false;
        const actionDateObject = new Date(actionDate);
        actionDateObject.setHours(0, 0, 0, 0);
        return actionDateObject <= today;
    });

    const futureSalesNotes = employerData.salesNotes.filter(note => {
        const actionDate = note.actionDate;
        if (!actionDate) return true;
        const actionDateObject = new Date(actionDate);
        actionDateObject.setHours(0, 0, 0, 0);
        return actionDateObject > today;
    });

    const actionDate = pastSalesNotes[0]?.actionDate || futureSalesNotes[0]?.actionDate;
    const actionDateObject = actionDate ? new Date(actionDate) : null;
    const isPastOrToday = actionDateObject && actionDateObject <= today;

    return (
        <div className="mb-4 border-2 border-white rounded-lg p-4">
            <div
                onClick={toggleOpen}
                className="flex justify-between items-center"
            >
                <div className="flex items-center">
                    <h2
                        className="text-lg font-semibold underline hover:text-sky-500 text-grey-800 cursor-pointer"
                        onClick={() => {
                            dispatch(setEmployer({ employer_id: employerData.employer.id, company_name: employerData.employer.company_name, logo: employerData.employer.logo }));
                            navigate(`/employers-center/${employerData.employer.id}/`, { state: { activeTab: "sales" } });
                        }}
                    >
                        {employerData.employer.company_name}
                    </h2>
                </div>
                {actionDate && (
                    <span className={`text-sm ml-auto mr-4 ${isPastOrToday ? 'text-amber-500' : 'text-gray-800'}`}>
                        Next Action Date: {new Date(actionDate).toLocaleDateString()} 
                    </span>
                )}
                <span>{isOpen ? '▲' : '▼'}</span>
            </div>
            {isOpen && (
                <div className="pt-4">
                    <ContactInfo contactInfo={employerData.contactInfo} />
                    <SalesNotes salesNotes={isPastOrToday ? pastSalesNotes : futureSalesNotes} />
                </div>
            )}
        </div>
    );
};

const ContactInfo = ({ contactInfo }) => {
    return (
        <div className="mb-4">
            <h3 className="text-md font-semibold mb-2">Contact Info</h3>
            <table className="table-auto w-full border-collapse">
                <tbody>
                    <tr>
                        <td className="border border-gray-300 px-4 py-2 font-bold w-1/4 bg-white">Name:</td>
                        <td className="border border-gray-300 px-4 py-2 bg-white">
                            {contactInfo.firstname1} {contactInfo.lastname1}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray-300 px-4 py-2 font-bold w-1/4 bg-white">Email:</td>
                        <td className="border border-gray-300 px-4 py-2 bg-white">
                            <a href={`mailto:${contactInfo.email1}`} className="text-blue-500">
                                {contactInfo.email1}
                            </a>
                        </td>
                    </tr>
                    {contactInfo.phone1 && (
                        <tr>
                            <td className="border border-gray-300 px-4 py-2 font-bold w-1/4 bg-white">Phone:</td>
                            <td className="border border-gray-300 px-4 py-2 bg-white">
                                <a href={`tel:${contactInfo.phone1}`} className="text-blue-500">
                                    {contactInfo.phone1}
                                </a>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

const SalesNotes = ({ salesNotes }) => {
    return (
        <div>
            <h3 className="text-md font-semibold mb-2">Tasks</h3>
            {salesNotes.length > 0 ? (
                salesNotes.map((note) => (
                    <div key={note.id} className="mb-4">
                        <table className="table-auto w-full border-collapse">
                            <tbody>
                                <tr>
                                    <td className="border border-gray-300 px-4 py-2 font-bold w-1/4 bg-white">Note:</td>
                                    <td className="border border-gray-300 px-4 py-2 bg-white">{note.note}</td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 px-4 py-2 font-bold w-1/4 bg-white">Date:</td>
                                    <td className="border border-gray-300 px-4 py-2 bg-white">
                                        {new Date(note.date).toLocaleDateString()}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 px-4 py-2 font-bold w-1/4 bg-white">Action Date:</td>
                                    <td className="border border-gray-300 px-4 py-2 bg-white">
                                        {new Date(note.actionDate).toLocaleDateString()}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-300 px-4 py-2 font-bold w-1/4 bg-white">Written By:</td>
                                    <td className="border border-gray-300 px-4 py-2 bg-white">{note.writtenBy}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ))
            ) : (
                <p className="text-gray-600">No sales notes available.</p>
            )}
        </div>
    );
};

export default EmployersList;
