"use client";
import { useState } from "react";
import { InfoIcon } from "./icons/InfoIcon";

export const ToolTip = ({
  children,
  title = "",
  message = "",
  cta = "Contact Us",
  ctaLink = "",
}) => {
  const [showToolTip, setShowToolTip] = useState(false);

  return (
    <div className="relative">
      {showToolTip && (
        <div className="show_tooltip">
          <div className="absolute w-[280px] shadow-lg bg-slate-200 p-2 rounded-lg top-[-8px] left-[-8px] pb-12 z-10">
            <div className="flex justify-start">
              <span
                className="cursor-pointer"
                onClick={() => setShowToolTip(!showToolTip)}
              >
                <InfoIcon />
              </span>
              <div className="pl-0 ml-[-20px]">
                <h3 className="font-bold mb-2">{title}</h3>
                <div className="text-xs">{children ? children : message}</div>
              </div>
              <button
                className="absolute top-0 right-0 p-2 pr-3 hover:text-red-600"
                onClick={() => setShowToolTip(!showToolTip)}
              >
                X
              </button>
            </div>
            <a
              href={ctaLink}
              className="absolute bottom-3 right-3 btn btn-xs btn-aj"
              target="_blank"
              rel="noreferrer"
            >
              {cta}
            </a>
          </div>
        </div>
      )}
      <span
        className="cursor-pointer"
        onClick={() => setShowToolTip(!showToolTip)}
      >
        <InfoIcon />
      </span>
    </div>
  );
};
