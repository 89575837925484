import React from "react";
import ACLogo from "./ACLogo";

function AcademicConnectPromo({ forceClass = "" }) {
  const domain = `https://www.academicjobs.com/`;
  return (
    <>
      <div className="hidden lg:block">
        <h2 className="m-0 mb-8 text-gray-blue font-bold text-4xl mt-10">
          About <ACLogo />
        </h2>

        <div
          className={`flex flex-col md:flex-row items-center justify-center pt-2 pb-4`}
        >
          <div className="flex flex-col items-center justify-between">
            <img
              src={"https://www.academicjobs.com/academic-connect-logo.svg"}
              alt="AcademicConnect Logo"
              className="lg:h-[236px] w-full"
            />
          </div>
          <a
            href={`${domain}/academic-connect`}
            className="grow"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://www.academicjobs.com/employer-dashboard/your-employer-network.svg"
              alt="Your Employer Network - Academic Connect"
              className="hidden md:block max-h-[236px]"
            />
          </a>
        </div>
      </div>
    </>
  );
}

export default AcademicConnectPromo;
