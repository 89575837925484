import { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputBlock3 from "../InputBlock3";
import { setCredentials, isLoggedInOn, isLoggedInOff } from "./authSlice";
import { useRegisterUserMutation } from "./authApiSlice";
// import usePersist from '../../hooks/usePersist'
// import useTitle from '../../hooks/useTitle'
//import PulseLoader from 'react-spinners/PulseLoader'
import { useSelector, useDispatch } from "react-redux";
import { setEmail1, setUserid, setCompany_name, setCountry } from "./authSlice";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import UniSearchBlock from "../UniSearchBlock";
import SignUpBenefits from "../../components/SignUpBenefits";
import SignUpUnis from "../../components/SignUpUnis";
import swal from "sweetalert";
import { useSendClientAdminRequestEmailMutation } from "../../store/apiSlice"; // Updated import

const SignUp = () => {
  const [sendFormDetails, { isLoading }] =
    useSendClientAdminRequestEmailMutation(); // Updated hook usage

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      institution: "",
      email: "",
      phone: "",
    },
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        institution: yup
          .string()
          .required("Institution or Company Name is required"),
        email: yup
          .string()
          .email("Email is invalid")
          .required("Email is required"),
        phone: yup.string().required("Phone is required"),
      })
    ),
  });

  // const location = useLocation();
  // const [nextPageYN, setNextPageYN] = useState(false);
  // const { from } = location.state || { from: { pathname: "/" } };
  // const productid = useSelector((state) => state.posts.productid);
  // const productQty = useSelector((state) => state.posts.productQty);
  // const addOnYN = useSelector((state) => state.posts.addOnYN);
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const [signUpBuy, { isLoading }] = useRegisterUserMutation();
  const onSubmit = async (data) => {
    try {
      const response = await sendFormDetails(data).unwrap();

      if (response.message === "Confirmation email sent successfully") {
        console.log("Form details sent successfully");
        swal({
          title: "Confirmation email sent!",
          content: {
            element: "p",
            attributes: {
              innerHTML: "A confirmation email will be sent to you shortly in order to activate your account.",
              style: "text-align: center; font-size: 16px;"
            }
          },
          icon: "success",
          buttons: false,
          timer: 5000,
        });

      } else if (response.message === "Admin request email sent successfully") {
        swal({
          title: "Request Sent!",
          content: {
            element: "p",
            attributes: {
              innerHTML: "You will be contacted shortly to verify your position at this organisation.",
              style: "text-align: center; font-size: 16px;"
            }
          },
          icon: "success",
          buttons: false,
          timer: 5000,
        });
      } else {
        console.log("Failed to send form details");
        swal("Error sending request.", "Please try again", "error");
      }
    } catch (err) {
      console.error("An unexpected error occurred", err);
      swal("Error sending request.", "Please try again", "error");
    }
  };

  let content;
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(true);
  }, []);
  const inputErrClass = "w-full text-xs font-bold text-red-700";
  const onEditorStateChange1 = (
    company_name,
    employer_id,
    Region,
    country,
    logo,
    employerPageURL,
    clientType
  ) => {
    setValue("company_name1", company_name);
    setValue("employer_id", employer_id || 0);
    setValue("Region", Region);
    setValue("country", country);
    setValue("logo", logo);
    setValue("institution", company_name); // Populate institution with company_name
  };
  content = (
    <div className="flex items-center justify-center min-h-screen bg-slate-50 ">
      <section className="container max-w-[1100px] px-4">
        {/* <div className="flex parent-div items-center justify-center">
          <img
            src="/banner-signup.png"
            alt="Networking & Collaborating"
            className="sign_up_banner flex items-center justify-center p-2"
          />
        </div> */}


        <div className="flex flex-col items-center lg:flex-row gap-12">
          <div className="rounded-lg border text-card-foreground shadow-sm px-10 pb-8 pt-2 bg-white w-full max-w-[460px]">
          <h3 className="m-0 mt-8 font-bold text-[#f4a10c] leading-tight text-center">
              Employer / Recruiter Sign Up
            </h3>
            <img
              src="/academic-jobs-logo.png"
              alt="Academic Jobs Logo"
              className="max-w-[250px] mx-auto"
            />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div className="flex flex-col gap-2">
                  <div className="grid w-full items-center gap-1.5">
                    <UniSearchBlock
                      register={register}
                      field="employer_name"
                      customKey="company_name"
                      label="Institution or Company Name*"
                      value1={""}
                      onChange={onEditorStateChange1}
                      forceClass="mb-3"
                    />
                    {errors.institution && (
                      <p className={inputErrClass}>
                        {errors.institution.message}
                      </p>
                    )}
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <InputBlock3
                      type="text"
                      field="firstName"
                      label="First Name*"
                      register={register}
                      errors={errors}
                      forceClass="mb-4"
                    />
                    <InputBlock3
                      type="text"
                      field="lastName"
                      label="Last Name*"
                      register={register}
                      errors={errors}
                      forceClass="mb-4"
                    />
                  </div>
                  <InputBlock3
                    type="email"
                    field="email"
                    label="Email*"
                    register={register}
                    errors={errors}
                    forceClass=""
                  />
                  <p className="text-sm ml-1 text-gray-600">
                    <strong>Note:</strong> Your email address must match the
                    organisation's domain for immediate access.
                  </p>
                  <InputBlock3
                    type="tel"
                    field="phone"
                    label="Phone*"
                    register={register}
                    errors={errors}
                    forceClass="mb-4"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2 mt-4">
                <div className="flex justify-between items-center">
                  <button
                    type="submit"
                    className="w-full btn bg-blue-600 text-white mt-2"
                  >
                    Request Access to University Portal
                  </button>
                </div>
                <p className="text-center text-sm text-gray-600 m-0 mt-4">
                  Already have an account? please&nbsp;
                  <Link className="text-blue-500 hover:underline" to="/login">
                    Sign in
                  </Link>
                </p>
              </div>
            </form>
          </div>
          <SignUpBenefits />
        </div>
        {/* <SignUpUnis /> */}
      </section>
    </div>
  );

  return content;
};

export default SignUp;
